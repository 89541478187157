.interna-planos {
  display: flex;
  flex-direction: column;
}
.planinterna__wrapper {
  display: flex;
  justify-content: space-between;
}
.planinterna__content {
  h3 {
    font-size: 3.2rem;
    color: #272832;
    font-family: "InterstatePlus-Bold";
    margin-bottom: 34px;
    line-height: 1.2;
    border-bottom: 1px solid #e1e1e1;
    width: 100%;
    display: flex;
    align-items: center;
    min-height: 90px;
  }
  &__left {
    width: 846px;
    h4 {
      font-size: 2.2rem;
      font-family: Arial, Helvetica, sans-serif;
      color: #393a42;
      line-height: 2.6rem;
      font-weight: 400;
      margin-bottom: 45px;
    }
    h5 {
      font-size: 2.2rem;
      color: #393a42;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      line-height: 2.6rem;
      margin-bottom: 20px;
    }
    h6 {
      font-size: 1.6rem;
      color: #393a42;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: bold;
      line-height: 2.3rem;
      margin-bottom: 28px;
    }
    p {
      font-size: 1.6rem;
      color: #393a42;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 2.3rem;
      margin-bottom: 28px;
    }
    ul {
      margin-bottom: 25px;
      li {
        font-family: Arial, Helvetica, sans-serif;
        line-height: 2.3rem;
        font-weight: 400;
        font-size: 1.6rem;
        margin-bottom: 28px;
        color: #393a42;
        position: relative;
        padding-left: 25px;
        &:before {
          content: url("../img/checked.png");
          display: inline;
          position: absolute;
          left: 0;
          height: 20px;
          width: 20px;
          /* top: 20px; */
        }
      }
      li:last-child {
        margin-bottom: 0;
      }
    }
    .planinterna__block {
      margin-bottom: 42px;
      ul:last-child {
        margin-bottom: 0;
      }
      .wrapper-select {
        position: relative;
        width: 100%;
        margin-bottom: 14px;
      }
      .wrapper-select input {
        display: none;
      }
      .select__atual {
        width: 100%;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding: 10px 20px;
        position: relative;
        min-height: 46px;
        border-radius: 4px;
        border: 2px solid #22beef;
        font-family: "InterstatePlus-Regular";
        font-size: 1.6rem;
        font-weight: bold;
        color: #333333;
        &:after {
          background-image: url(../img/arrow-down-blue.png);
          background-repeat: no-repeat;
          background-size: 15px;
          content: "";
          display: block;
          height: 13px;
          position: absolute;
          right: 16px;
          top: 15px;
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
          width: 15px;
          font-weight: bold;
        }
      }
      .select__atual.open {
        border-bottom: none;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        border-bottom: 0;
        &:after {
          -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
        }
      }
      .select__atual.open ~ .select__opcoes {
        display: block;
        margin-top: -2px;
      }
      .select__opcoes {
        width: 100%;
        display: block;
        border-radius: 4px;
        border: 2px solid #22beef;
        border-color: #22beef;
        border-top: none;
        display: none;
      }
      .select__opcoes__label {
        display: flex;
        width: 100%;
        /* align-items: center; */
        background-color: #fff;
        padding: 20px;
        padding-top: 8px;
        cursor: pointer;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 1.6rem;
        line-height: 2.3rem;
        font-weight: 400;
        color: #333333;
        flex-direction: column;
        .planinterna__block {
          margin-bottom: 0;
        }
      }
    }
    .planinterna__block:last-of-type {
      margin-bottom: 98px;
    }
  }
  &__right {
    width: 263px;
    .plainterna__materiais {
      width: 263px;
      border: 1px solid #dadada;
      background-color: #efefef;
      padding: 0 1px;
    }
    .materiais__title {
      font-family: Arial, Helvetica, sans-serif;
      color: #393a42;
      font-size: 2.2rem;
      font-weight: bold;
      line-height: 2.6rem;
      height: 58px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #e1e1e1;
      padding: 0 18px;
    }
    .materiais__link {
      height: 58px;
      display: flex;
      align-items: center;
      font-size: 1.6rem;
      font-family: Arial, Helvetica, sans-serif;
      color: #393a42;
      border-bottom: 1px solid #e1e1e1;
      img {
        margin: 0 18px;
      }
    }
    .materiais__link:last-of-type {
      border-bottom: 0;
    }
  }
}
@media (min-width: 768px) and (max-width: 1169px) {
  .planinterna__wrapper {
    flex-direction: column;
  }

  .planinterna__content {
    h3 {
      height: 90px;
      display: flex;
      align-items: center;
      margin: 0 0 18px 0;
    }
    &__left {
      width: 100%;
      display: flex;
      order: 1;
      flex-direction: column;
      .planinterna__block {
        .select__atual__text {
          width: 95%;
        }
      }
      .planinterna__block:last-of-type {
        margin-bottom: 62px;
      }
      h4 {
        font-size: 2rem;
        line-height: 1.2;
        margin-bottom: 20px;
        br {
          display: none;
        }
      }
      h5 {
        font-size: 2rem;
        line-height: 2.6rem;
      }
      h6 {
        font-size: 1.5rem;
        line-height: 1.2;
        margin-bottom: 15px;
      }
      ul {
        margin-bottom: 18px;
        li {
          font-size: 1.5rem;
          line-height: 1.2;
          margin-bottom: 14px;
        }
      }
      p {
        font-size: 1.5rem;
        line-height: 1.2;
        margin-bottom: 20px;
      }
    }
    &__right {
      width: 100%;
      display: flex;
      order: 0;
      .plainterna__materiais {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 18px;
        .materiais__title {
          height: 40px;
          width: 100%;
          font-size: 1.5rem;
          padding: 0 26px;
        }
        .materiais__link {
          height: 60px;
          display: flex;
          justify-content: center;
          border-bottom: 0;
          cursor: pointer;
          img {
            margin: 0 15px 0 0;
          }
        }
        .materiais__link:nth-of-type(1) {
          width: 235px;
          border-right: 1px solid #e1e1e1;
        }
        .materiais__link:nth-of-type(2) {
          width: 225px;
          border-right: 1px solid #e1e1e1;
        }
        .materiais__link:nth-of-type(3) {
          width: 284px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .planinterna__content {
    h3 {
      height: 68px;
      font-size: 2.4rem;
      line-height: 2.5rem;
      letter-spacing: -0.01em;
      margin: 0;
      display: flex;
      align-items: center;
      margin-bottom: 28px;
    }
    &__left {
      width: 100%;
      display: flex;
      flex-direction: column;
      order: 1;
      h4 {
        font-size: 1.7rem;
        line-height: 1.2;
        margin-bottom: 25px;
        br {
          display: none;
        }
      }
      h5 {
        font-size: 1.7rem;
        line-height: 1.2;
        margin-bottom: 18px;
      }
      h6 {
        font-size: 1.5rem;
        line-height: 1.2;
        margin-bottom: 16px;
      }
      ul {
        li {
          font-size: 1.5rem;
          line-height: 1.2;
          margin-bottom: 20px;
        }
      }
      .planinterna__block {
        margin-bottom: 35px;
        .select__atual__text {
          width: 92%;
        }
        .wrapper-select:last-of-type {
          margin-bottom: 0;
        }
      }
      p {
        font-size: 1.5rem;
        line-height: 1.2;
        margin-bottom: 18px;
      }
    }
    &__right {
      width: 100%;
      display: flex;
      order: 0;
      margin: 0 auto 35px;
      width: 100%;
      .plainterna__materiais {
        width: 100%;
        .materiais__title {
          font-size: 1.7rem;
          padding: 0 15px;
        }
        .materiais__link {
          img {
            margin: 0 15px;
          }
        }
      }
    }
  }
  .planinterna__wrapper {
    flex-direction: column;
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  .planinterna__content__right {
    width: 100%;
  }
}
