.breadcrumb {
  height: 40px;
  width: 100%;
  background-color: #666666;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0.01em;
  font-family: Arial, Helvetica, sans-serif;
  .grid * {
    display: inline-flex;
  }
  .breadcrumb__first {
    color: #fff;
    a {
      color: #fff;
      cursor: pointer;
    }
    ul li a {
      color: #ccc;
    }
    ul {
      ul {
        li {
          // width: 975px;
          // display: inline;
          // white-space: nowrap;
          // overflow: hidden;
          // text-overflow: ellipsis;
        }
      }
    }
    &:before {
      content: "";
      margin: 0;
    }
  }
  li {
    color: #cccccc;
    &:before {
      content: "/";
      font-family: Arial, Helvetica, sans-serif;
      color: #fff;
      margin: 0 10px;
    }
  }
}
.page__header {
  min-height: 120px;
  width: 100%;
  background-color: #f5d240;
  display: flex;
  align-items: center;
  &__title {
    font-size: 61px;
    color: #272832;
    letter-spacing: -0.025em;
    font-family: "InterstatePlus-Bold";
  }
}
.btn__up {
  display: none;
}
.br--helper {
  display: none;
}
.btn__planos {
  height: 38px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: "InterstatePlus-Bold";
  color: #fff;
  margin-top: 20px;
  border-radius: 5px;
  font-size: 1.6rem;
  padding: 0 22px;
  margin-right: 14px;
  &--roxo {
    background-color: #705785;
  }
  &--azul {
    background-color: #1bbcef;
  }
}

.wrapper__loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  z-index: 999;
}
.loader {
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-left: -25px;
  margin-top: -25px;
}

@media (min-width: 768px) and (max-width: 1169px) {
  .br--helper {
    display: inline-block;
  }
  .breadcrumb {
    .breadcrumb__first {
      ul {
        ul {
          li {
            width: 634px;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .btn__up {
    display: block;
    opacity: 0;
    transition: all 0.5s ease;
    position: fixed;
    left: 5px;
    bottom: 8px;
    z-index: 9999;
  }
  .breadcrumb {
    background-color: #666;
  }
  .page__header {
    &__title {
      min-height: 105px;
      font-size: 3.8rem;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
  }
}
@media (min-width: 414px) and (max-width: 767px) and (orientation: portrait) {
  .breadcrumb {
    .breadcrumb__first {
      ul {
        ul {
          li {
            // max-width: 41%;
            // width: inherit;
          }
        }
      }
    }
  }
}
@media (min-width: 375px) and (max-width: 413px) and (orientation: portrait) {
  .breadcrumb {
    .breadcrumb__first {
      ul {
        ul {
          li {
            // max-width: 34%;
            // width: inherit;
          }
        }
      }
    }
  }
}
@media (min-width: 667px) and (max-width: 767px) and (orientation: landscape) {
  .breadcrumb {
    .breadcrumb__first {
      ul {
        ul {
          li {
            // max-width: 80%;
            // width: inherit;
          }
        }
      }
    }
  }
}
@media (min-width: 568px) and (max-width: 666px) and (orientation: landscape) {
  .breadcrumb {
    .breadcrumb__first {
      ul {
        ul {
          li {
            // max-width: 65%;
            // width: inherit;
          }
        }
      }
    }
  }
}
@media (max-width: 374px) {
  .breadcrumb {
    .breadcrumb__first {
      ul {
        ul {
          li {
            // max-width: 26%;
            // width: inherit;
          }
        }
      }
    }
  }
}
