.main__lpp {
  display: flex;
  flex-direction: column;
}
.img__composicao {
  margin: 42px 0 0 0;
  padding-left: 62px;
}
.landing-planos {
  .page__header {
    min-height: 185px;
  }
  .lpb__options {
    &__content {
      p:last-of-type {
        margin-bottom: 36px;
      }
    }
  }
}
@media (min-width: 768px) and (max-width: 1169px) {
  .img__composicao {
    padding: 0;
    margin: 42px auto 0;
    display: block;
  }
  .grid__lpa__content {
    flex-direction: column;
    &.lpa__header__planos {
      flex-direction: inherit;
    }
  }
  .landing-planos {
    .page__header {
      min-height: 121px;
    }
    .lpa__header__planos {
      flex-direction: inherit;
    }
    .btn__planos.safari-fix {
      margin-top: 10px !important;
    }
    .lpa__header__planos {
      display: flex;
      justify-content: space-between;
    }
    .page__header__title {
      display: inline-flex;
    }
    .btn__planos--azul {
      margin-right: 0;
    }
    .lpb__options {
      ul li label {
        text-align: center;
      }
      &__content {
        p:last-of-type {
          margin-bottom: 40px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .img__composicao {
    max-width: 100%;
    height: auto;
    padding: 0;
  }
  .landing-planos {
    .page__header {
      min-height: 196px;
      align-items: inherit;
    }
    .btn__planos {
      width: 100%;
      font-size: 1.8rem;
      margin: 0;
    }
    .btn__planos--roxo {
      margin-bottom: 10px;
    }
    .page__header__title {
      min-height: inherit;
      padding-top: 30px;
      padding-bottom: 10px;
    }
  }
}
