.main__lpa {
  display: flex;
  flex-direction: column;
}
.grid__lpa__content {
  display: flex;
  flex-direction: column;
}
.lpa__content {
  display: flex;
  width: 100%;
  align-items: center;
  padding-bottom: 92px;
  &__title {
    min-height: 92px;
    display: flex;
    width: 100%;
    align-items: center;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 40px;
    h3 {
      font-size: 3.2rem;
      color: #272832;
      letter-spacing: -0.01em;
      font-family: "InterstatePlus-Bold";
    }
  }
  &__desc {
    display: flex;
  }
  &__left {
    width: 390px;
    display: flex;
    align-items: center;
    img {
      margin: 0 auto;
    }
  }
  &__right {
    width: 780px;
    padding: 0 28px 0 10px;
    h4 {
      font-size: 2.2rem;
      color: #393a42;
      line-height: 2.6rem;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 500;
      margin-bottom: 20px;
    }
    p {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1.6rem;
      line-height: 2.3rem;
      color: #393a42;
      font-weight: 500;
      margin-bottom: 25px;
    }
  }
}

@media (min-width: 768px) and (max-width: 1169px) {
  .lpa__content {
    padding-bottom: 25px;
  }
  .lpa__content__title {
    margin-bottom: 20px;
    h3 {
      font-size: 3rem;
    }
  }
  .lpa__content__left {
    width: 25%;
    align-items: flex-start;
    padding-top: 18px;
  }
  .lpa__content__right {
    width: 75%;
    padding-right: 20px;
    h4 {
      font-size: 2rem;
      line-height: 1.2;
    }
    p {
      font-size: 1.5rem;
      line-height: 1.2;
      margin-bottom: 18px;
    }
  }
}
@media (max-width: 767px) {
  .grid__lpa__content {
    max-width: 90%;
  }
  .lpa__content {
    position: relative;
  }
  .lpa__content__title {
    min-height: 65px;
    margin-bottom: 22px;
    h3 {
      padding: 0 8px;
      font-size: 2.4rem;
    }
  }
  .lpa__content__desc {
    flex-direction: column;
  }
  .lpa__content__left {
    height: 212px;
    position: relative;
    width: 100%;
    overflow: hidden;
    margin-bottom: 15px;
    padding: 0;
    &:after {
      content: "";
      background-image: url("../img/box-shadow-bottom.png");
      background-repeat: repeat-x;
      height: 28px;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
    }
    img {
      position: absolute;
      top: 0;
      width: 110px;
      left: 50%;
      margin-left: -55px;
    }
  }
  .lpa__content__right {
    width: 100%;
    padding: 0;
    img {
      max-width: 100%;
      height: auto;
    }
    h4 {
      font-size: 1.7rem;
      line-height: 1.2;
    }
    p {
      font-size: 1.5rem;
      line-height: 1.2;
      margin-bottom: 20px;
    }
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  .lpa__content__left {
    &:after {
      width: 50%;
      margin-left: 25%;
    }
  }
  .lpa__content__right p {
    font-size: 1.5rem !important;
  }
}
