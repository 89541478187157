.body__error {
  header {
    height: 118px;
    background-color: #fff;
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    .header__nav {
      display: none;
    }
    .header__logo {
      margin: 0 auto;
      width: inherit;
      padding: 15px 0;
      text-align: center;
    }
  }
  footer {
    display: none;
  }
  .main__error {
    height: 100vh;
    margin-top: -118px;
    padding-top: 118px;
  }
}
.main__error {
  background-color: #f5d240;
  height: 795px;
  display: flex;
  flex-direction: column;
}
.error__content {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  img {
    margin-right: 40px;
  }
}
.error__destaque {
  display: flex;
  flex-direction: column;
  margin-top: -130px;
  h1 {
    font-size: 6.1rem;
    letter-spacing: -0.025em;
    color: #272832;
    font-family: "InterstatePlus-Bold";
    line-height: 1.2;
    margin-bottom: 5px;
  }
  p {
    font-size: 1.6rem;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 2.3rem;
    color: #2d2e37;
    width: 420px;
    margin-bottom: 18px;
  }
  .btn__error {
    width: 233px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "InterstatePlus-Bold";
    background-color: #00bef2;
    font-size: 1.6rem;
    color: #fff;
    text-transform: uppercase;
    border-radius: 20px;
  }
}
@media (min-width: 768px) and (max-width: 1169px) {
  .body__error {
    .main__error {
      height: 100vh;
      padding-top: 106px;
      margin-top: -106px;
    }
    .header__nav__mobile {
      display: none;
    }
    header {
      background-color: #fff;
      width: 100%;
      height: 106px;
      /* height: 14vh; */
      display: flex;
      align-items: center;
      &:after {
        content: none;
      }
      .header__logo {
        margin: inherit;
        position: relative;
        left: inherit;
        top: inherit;
        padding: 0;
        img {
          height: 83px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .body__error {
    header {
      position: relative;
      height: 64px;
      .header__logo {
        padding: 0;
        margin: 0;
        position: relative;
        left: inherit;
        top: inherit;
      }
    }
    .header__nav__mobile {
      display: none;
    }
    .main__error {
      height: 100vh;
      margin-top: -64px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 64px;
    }
  }

  .error__content {
    flex-direction: column;
    justify-content: inherit;
    img {
      height: 221px;
      margin: 26px 0 20px 0;
    }
  }
  .error__destaque {
    margin: 0;
    h1 {
      font-size: 3.8rem;
      text-align: center;
    }
    p {
      width: 90%;
      font-size: 1.5rem;
      margin: 0 auto 20px;
      text-align: center;
      line-height: 1.2;
    }
    .btn__error {
      margin: 0 auto 50px;
    }
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  .body__error .main__error {
    height: inherit;
  }
}
