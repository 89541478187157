.main__interna {
  .page__header {
    min-height: 120px;
  }
  .platinterna__notfull {
    width: 846px;
  }
  .planinterna__full {
    width: 945px;
  }
  .planinterna__block {
    a {
      font-family: "InterstatePlus-Regular";
      font-size: 1.5rem;
      color: #666666;
      text-decoration: underline;
      margin-bottom: 22px;
      display: inline-block;
    }
    span {
      font-family: "InterstatePlus-Regular";
      font-size: 1.5rem;
      color: #666666;
      margin-right: 5px;
      text-decoration: none;
      display: inline-block;
    }
  }
  .planinterna__formularios {
    margin-bottom: 72px;
    h5 {
      margin-bottom: 35px;
    }
  }
  .planinterna__tributacao {
    margin-bottom: 50px;
  }
  .planinterna__beneficios {
    h5 {
      margin-bottom: 35px;
    }
  }
  .beneficios__block {
    width: 850px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
  }
  .beneficios__block:last-of-type {
    .beneficio__content {
      margin-bottom: 0;
    }
  }
  .beneficio__img {
    padding-left: 28px;
    padding-top: 5px;
  }
  .beneficio__content {
    width: 650px;
    h6 {
      font-size: 1.6rem;
      line-height: 2.2rem;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 700;
      margin-bottom: 8px;
    }
    p {
      font-size: 1.6rem;
      /* line-height: 2.2rem; */
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 400;
      line-height: 1.5;
      margin-bottom: 22px;
    }
  }
  .formularios__content {
    display: flex;
    width: 948px;
    justify-content: space-between;
  }
  .formularios__block {
    width: 454px;
    .formulario__list {
      li {
        height: 52px;
        display: flex;
        align-items: center;
        margin: 0;
        border-bottom: 1px solid #d1d1d1;
        padding-left: 62px;
        font-family: "InterstatePlus-Regular";
        font-size: 1.8rem;
        font-weight: 500;
        letter-spacing: -0.01em;
        color: #272832;
        &:before {
          left: 26px;
        }
      }
      &--purple {
        li {
          padding: 0;
          &:before {
            content: none;
          }
          a {
            -webkit-align-items: center;
            align-items: center;
            border-bottom: 1px solid #d1d1d1;
            color: #272832;
            display: -webkit-flex;
            display: flex;
            font-family: "InterstatePlus-Regular";
            font-size: 1.8rem;
            font-weight: 500;
            height: 52px;
            letter-spacing: -0.01em;
            margin: 0;
            padding-left: 62px;
            text-decoration: none;
            &:before {
              content: url("../img/ico-download-form.png");
              display: inline;
              height: 20px;
              position: absolute;
              width: 20px;
              left: 26px;
              top: 15px;
            }
          }
        }
      }
      &--blue {
        li {
          padding: 0;
          &:before {
            /* content: url("../img/ico-download-form-blue.png"); */
            content: none;
          }
          a {
            -webkit-align-items: center;
            align-items: center;
            border-bottom: 1px solid #d1d1d1;
            color: #272832;
            display: -webkit-flex;
            display: flex;
            font-family: "InterstatePlus-Regular";
            font-size: 1.8rem;
            font-weight: 500;
            height: 52px;
            letter-spacing: -0.01em;
            margin: 0;
            padding-left: 62px;
            text-decoration: none;
            &:before {
              content: url("../img/ico-download-form-blue.png");
              display: inline;
              height: 20px;
              left: 0;
              position: absolute;
              width: 20px;
              left: 26px;
              top: 15px;
            }
          }
        }
      }
    }
  }
  .formulario__title {
    font-size: 2rem;
    font-family: "InterstatePlus-Regular";
    font-weight: 500;
    letter-spacing: -0.01em;
    color: #fff;
    width: 100%;
    border-radius: 20px;
    height: 45px;
    display: flex;
    align-items: center;
    padding-left: 28px;
    &--purple {
      background-color: #701d82;
    }
    &--blue {
      background-color: #00bef2;
    }
  }
  .planinterna__half {
    display: flex;
    justify-content: space-between;
    .planinterna__block--half {
      width: 458px;
      p {
        min-height: 207px;
      }
    }
  }
  .planinterna__table {
    .table__content {
      display: flex;
      width: 100%;
    }
    .content__left {
      width: 359px;
      border-right: 2px solid #fff;
      height: 100%;
      display: flex;
      flex-direction: column;
      .title__left {
        height: 41px;
        background-color: #1bbcef;
        width: 100%;
        font-size: 1.4rem;
        color: #fff;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding-left: 16px;
        border-bottom: 1px solid #fff;
      }
      span {
        height: 42px;
        display: flex;
        align-items: center;
        margin: 0;
        width: 100%;
        padding-left: 16px;
        font-size: 1.4rem;
        color: #272832;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        border-bottom: 1px solid #fff;
      }
      span:nth-child(odd) {
        background-color: #f6f6f6;
      }
      span:nth-child(even) {
        background-color: #efefef;
      }
    }
    .content__right {
      width: 97px;
      height: 100%;
      display: flex;
      align-items: center;
      flex-direction: column;
      .title__right {
        height: 41px;
        background-color: #1bbcef;
        width: 100%;
        font-size: 1.4rem;
        color: #fff;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        display: flex;
        align-items: center;
        padding-left: 16px;
        border-bottom: 1px solid #fff;
      }
      span {
        height: 42px;
        display: flex;
        align-items: center;
        width: 100%;
        margin: 0;
        padding-left: 16px;
        font-size: 1.4rem;
        color: #272832;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        border-bottom: 1px solid #fff;
      }
      span:nth-child(odd) {
        background-color: #f6f6f6;
      }
      span:nth-child(even) {
        background-color: #efefef;
      }
    }
    .table__content--3 {
      .title__left {
        height: 60px;
        width: 100%;
      }
      .content__left {
        width: 205px;
        span {
          width: 100%;
        }
      }
      .content__mid {
        width: 103px;
        border-right: 2px solid #fff;
        span {
          height: 42px;
          display: flex;
          align-items: center;
          width: 100%;
          margin: 0;
          padding-left: 16px;
          font-size: 1.4rem;
          color: #272832;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 500;
          border-bottom: 1px solid #fff;
        }
        span:nth-child(odd) {
          background-color: #f6f6f6;
        }
        span:nth-child(even) {
          background-color: #efefef;
        }
      }
      .title__mid {
        height: 60px;
        width: 103px;
        background-color: #1bbcef;
        color: #fff;
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        border-right: 2px solid #fff;
        padding: 0 11px;
        border-bottom: 1px solid #fff;
      }
      .content__right {
        width: 146px;
      }
      .title__right {
        height: 60px;
        width: 100%;
        padding: 0 12px;
        span {
          width: 100%;
        }
      }
    }
  }
  .planinterna__relatorios {
    width: 845px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 60px;
    margin-top: 40px;
  }
  .relatorio__box {
    width: 262px;
    height: 396px;
    border: 1px solid #e1e1e1;
    padding: 0 10px;
    margin-bottom: 24px;
    .relatorio__link {
      display: block;
      margin-bottom: 14px;
    }
    a {
      font-family: "InterstatePlus-Regular";
      font-weight: 500;
      color: #666666;
      text-decoration: underline;
      font-size: 1.5rem;
      letter-spacing: -0.01em;
      text-decoration: underline;
      width: 216px;
      display: inline-block;
      line-height: 1.2;
    }
    span {
      font-family: "InterstatePlus-Regular";
      font-weight: 500;
      color: #666666;
      font-size: 1.5rem;
      letter-spacing: -0.01em;
      text-decoration: none;
      margin-right: 4px;
      position: relative;
      top: 0;
      display: inline;
      vertical-align: top;
    }
  }
  .relatorio__ano {
    font-size: 2.3rem;
    color: #272832;
    margin-top: 14px;
    margin-bottom: 10px;
    font-family: "InterstatePlus-Bold";
    /* font-weight: 700; */
    text-align: center;
  }
  .relatorio__img {
    text-align: center;
    margin-bottom: 18px;
  }
}
.planinterna__content__left {
  .planinterna__block {
    .select__atual__text {
      color: #333;
      cursor: pointer;
      font-family: "InterstatePlus-Regular";
      font-size: 1.6rem;
      font-weight: 700;
    }
  }
}

@media (min-width: 768px) and (max-width: 1169px) {
  .main__interna {
    .beneficio__content {
      width: 540px;
    }
    .planinterna__relatorios {
      width: 100%;
    }
    .relatorio__box {
      width: 229px;
      span {
        margin-right: 1px;
      }
      a {
        width: 190px;
      }
    }

    .planinterna__table {
      .content__right {
        width: 100px;
      }
      .table__content--3 .title__mid {
        border-right: 4px solid #fff;
      }
      .content__left {
        width: 647px;
        border-right: 4px solid #fff;
      }
      .table__content {
        &.table__content--3 {
          .content__left {
            width: 500px;
          }
          .content__mid {
            span {
              border-right: 2px solid #fff;
            }
          }
          .content__right {
          }
        }
      }
    }
    .platinterna__notfull {
      width: 100%;
    }
    .beneficios__block {
      width: 100%;
    }
    .formularios__block {
      width: 350px;
      .formulario__list {
        li {
          font-size: 1.6rem;
          padding-left: 0;
          a {
            font-size: 1.6rem;
            padding-left: 53px;
            &:before {
              left: 17px;
            }
          }
          &:before {
            left: 17px;
          }
        }
      }
    }
    .planinterna__half {
      flex-direction: column;
      .planinterna__block--half {
        width: 100%;
        p {
          min-height: inherit;
          margin-bottom: 35px;
        }
      }
    }
  }

  .main__interna .planinterna__full {
    width: 100%;
  }
  .main__interna .formularios__content {
    width: 100%;
  }
}
@media (max-width: 767px) {
  .main__interna {
    .formularios__block {
      .formulario__list li:before {
        top: 18px;
      }
    }
    .page__header__title {
      padding-top: 0;
      padding-bottom: 0;
      min-height: 120px;
    }
    .platinterna__notfull {
      width: 100%;
    }
    .planinterna__block {
      a {
        margin-bottom: 32px;
      }
    }
    .planinterna__half {
      .planinterna__block--half {
        p {
          min-height: inherit;
          margin-bottom: 25px;
        }
      }
    }
    .planinterna__content__left {
      .planinterna__block:last-of-type {
        margin-bottom: 40px;
      }
    }
    .planinterna__formularios {
      margin-bottom: 30px;
    }
    .formularios__block {
      .formulario__list {
        li {
          padding-right: 16px;
        }
      }
    }
    .relatorio__box {
      width: 100%;
      padding: 0 22px 30px 22px;
      min-height: 357px;
      height: inherit;
      a {
        font-size: 1.5rem;
      }
    }
    .planinterna__relatorios {
      width: 100%;
    }
    .relatorio__ano {
      font-size: 2.5rem;
      margin-top: 15px;
    }
    .relatorio__img {
      margin-bottom: 24px;
    }
    .planinterna__table {
      .content__left {
        width: 76%;
        span {
          font-size: 1.2rem;
          padding-left: 12px;
        }
        .title__left {
          font-size: 1.2rem;
          padding-left: 12px;
        }
      }
      .table__content--3 {
        .content__left {
          width: 40%;
          .title__left {
            width: 100%;
          }
        }
        .content__mid {
          width: 32%;
          .title__mid {
            width: 100%;
            border-right: 0;
          }
        }
        .content__right {
          width: 28%;
          .title__right {
            width: 100%;
          }
        }
      }
      .content__mid {
        .title__mid {
          font-size: 1.2rem;
          padding-left: 12px;
        }
        span {
          font-size: 1.2rem;
          padding-left: 12px;
        }
      }
      .content__right {
        width: 24%;
        span {
          font-size: 1.2rem;
          padding-left: 12px;
        }
        .title__right {
          font-size: 1.2rem;
          padding: 0 6px;
          justify-content: center;
        }
      }
    }
    .planinterna__full {
      width: 100%;
    }
    .formularios__content {
      width: 100%;
      flex-direction: column;
    }
    .planinterna__half {
      flex-direction: column;
      .planinterna__block--half {
        width: 100%;
      }
    }
    .formularios__block {
      width: 100%;
      margin-bottom: 30px;
      .formulario__list {
        li {
          font-size: 1.6rem;
          line-height: 2.4rem;
          height: 60px;
          &:before {
            content: none;
          }
          a {
            font-size: 1.6rem;
            line-height: 2.4rem;
            height: 60px;
            &:before {
            }
          }
        }
      }
    }

    .beneficios__block {
      flex-direction: column;
      width: 100%;
    }
    .beneficios__block:nth-of-type(1) {
      .beneficio__img {
        img {
          width: 100px;
        }
      }
    }
    .beneficios__block:nth-of-type(3) {
      .beneficio__img {
        img {
          width: 85px;
        }
      }
    }
    .beneficio__content {
      width: 100%;
    }
    .beneficio__img {
      padding-left: 0;
      margin-bottom: 15px;
    }

    .planinterna__content__left .planinterna__block {
      .select__atual__text {
        color: #333;
        cursor: pointer;
        display: flex;
        font-family: "InterstatePlus-Regular";
        font-size: 1.6rem;
        font-weight: 500;
      }
    }
  }
  .relatorio__box__helper {
    width: 100%;
    margin: 0 auto;
    max-width: 325px;
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  .main__interna .relatorio__box {
    .relatorio__box__helper {
      width: 100%;
      margin: 0 auto;
      max-width: 325px;
    }
    .relatorio__link {
      text-align: left;
    }
    a {
      width: inherit;
    }
  }
}
