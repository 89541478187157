* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  list-style: none;
  text-decoration: none;
}
html {
  font-size: 62.5%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%; /* Prevent font scaling in landscape while allowing user zoom */
}
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h1,
h2,
h3 {
  font-weight: inherit;
}
area {
  outline: 0;
}
input:focus {
  outline: 0;
}
input::-webkit-contacts-auto-fill-button {
  visibility: hidden;
  display: none !important;
  pointer-events: none;
  position: absolute;
  right: 0;
}
button,
input[type="submit"],
input[type="email"] {
  margin: 0;
  border: 0;
  padding: 0;
  display: inline-block;
  vertical-align: middle;
  white-space: normal;
  background: none;
  line-height: 1;
  outline: none;
  -webkit-appearance: none;
  border-radius: 0;
}
input,
textarea {
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
::-webkit-search-decoration {
  display: none;
}
.overlay__bg {
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 998;
  background-color: rgb(239, 239, 239);
  opacity: 0.8;
  left: 0;
  top: 0;
}
.close__overlay {
  background-image: url("../img/overlay-close.png");
  height: 29px;
  width: 30px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 0;
  right: -13px;
  top: -13px;
}
.overlay__iframe {
  position: fixed;
  z-index: 999;
  width: 872px;
  height: 542px;
  left: 50%;
  top: 50%;
  margin-left: -436px;
  margin-top: -271px;
  background-color: #fff;
  border: 0;
  padding: 20px;
  &--external {
    min-height: 100px;
    height: inherit;
  }
}

.overlay__iframe--home {
    height: auto;
    margin-top: -135px;
}

.overlay__bg__text {
  color: #393a42;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  line-height: 2.5rem;
  margin-bottom: 10px;
  text-align: left;
}
.overlay__iframe iframe {
  width: 100%;
  height: 100%;
  border: 0;
}
.glide-2 {
  position: relative;
  width: 100%;
  box-sizing: border-box;
}
.glide .glide__controls {
  width: 1170px;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  margin-left: -585px;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  top: 50%;
  height: 1px;
  margin-top: -60px;
}
.glide .glide__controls.ie-flex-fix {
  height: 70px;
}
.glide .glide__controls button, 
.glide-2 .glide__controls button {
  cursor: pointer;
}
.btn-glide-left {
  margin-left: -120px;
  height: 69px;
}
.btn-glide-right {
  margin-right: -120px;
  height: 69px;
}
.glide__bullets {
  bottom: 125px;
}
.glide__bullet {
  height: 18px;
  width: 18px;
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.49);
}
.glide__bullet--active {
  position: relative;
}
.glide__bullet--active:after {
  content: "";
  position: absolute;
  background: #22beef;
  height: 10px;
  width: 10px;
  left: 50%;
  border-radius: 50%;
  margin-left: -5px;
  top: 50%;
  margin-top: -5px;
}

/* Grid */
.grid {
  width: 1170px;
  padding: 0 15px;
  margin: 0 auto;
}

.videos .grid {
  width: 1060px;
}

.grid__justify {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.grid__header,
.grid__footer,
.grid__slide {
  display: flex;
}
.grid__slide {
  padding-top: 78px;
  position: relative;
}
/* Header */
.header__nav {
  padding-top: 80px;
  height: 136px;
  width: 946px;
  position: relative;
}
.header___nav__title__mob {
  display: none;
}
.header__logo {
  width: 195px;
  padding: 22px 0 0 10px;
}
.header__nav__main {
  width: 472px;
  display: flex;
  justify-content: space-between;
}
.header__nav__main li a {
  font-family: "InterstatePlus-Bold";
  font-size: 1.6rem;
  color: #393a42;
  letter-spacing: -0.01em;
}
.header__nav__top {
  width: 478px;
  display: flex;
  position: absolute;
  right: 0;
  top: 18px;
}
.header__nav__top ul {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.header__nav__top ul li a {
  font-family: "InterstatePlus-Light";
  font-size: 1.3rem;
  color: #2b2c35;
  letter-spacing: -0.01em;
  display: flex;
  align-items: center;
}
.header__nav__top ul li a span {
  margin-left: 6px;
}
.header__nav__buttons {
  position: absolute;
  right: 0;
  bottom: 24px;
  width: 334px;
  display: flex;
  justify-content: flex-end;
}
.header__nav__mobile {
  display: none;
}
.chk--mobile {
  display: none;
}
.header__logo img {
  width: 100px;
  height: 86px;
}
/* Botões */
.btn {
  cursor: pointer;
  height: 40px;
  border-radius: 5px;
  font-family: "InterstatePlus-Bold";
  font-size: 1.6rem;
  letter-spacing: -0.01em;
  text-transform: uppercase;
  color: #393a42;
}
.btn__external {
  background-color: #00bef2;
  border-radius: 25px;
  color: #fff;
  height: 45px;
  width: 170px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}
.btn__simulador {
  width: 140px;
  border: 2px solid #666666;
}
.btn__restrita {
  width: 176px;
  background-color: #f5d240;
}
.btn__leia {
  height: 39px;
  width: 138px;
  background-color: #fff;
  border: 2px solid #00bef2;
  color: #00bef2;
  margin: 14px auto 0;
}
.btn__simulacao {
  width: 263px;
  height: 45px;
  background-color: #00bef2;
  color: #fff;
  border-radius: 25px;
  margin: 0 auto;
}
.btn__vervideo {
  height: 48px;
  width: 200px;
  background-color: #00bef2;
  color: #fff;
  border-radius: 25px;
  font-size: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 42px;
}
.btn__vervideo--azul {
  background-color: #efcb13;
  color: #393a42;
}
.btn__vervideo--roxo {
  background-color: #00bef2;
  color: #fff;
}
.btn__vervideo--amarelo {
  color: #fff;
  background-color: #6c2a80;
}
.grid__slide .btn__vervideo img {
  margin: 0;
  margin-left: 16px;
  align-self: center;
}
.grid__slide .btn__vervideo .img-leia {
  height: 25px;
}
/* Slider */
.slider {
  height: 516px;
}
.glide .glide__slides {
  height: 516px;
}
.glide-2 .glide__slides {
  white-space: normal !important;
}
.slide__principal h1 {
  font-size: 6rem;
  letter-spacing: -0.025em;
  color: #393a42;
  font-family: "InterstatePlus-Bold";
}
.grid__slide img {
  align-self: flex-start;
  margin-right: 30px;
}
.grid__slide .img-boneco-1 {
  margin-left: auto;
  margin-right: 128px;
  margin-top: -62px;
}
.grid__slide .img-boneco-2 {
  margin-left: auto;
  margin-right: 40px;
  margin-top: 0;
}
/* News */
.news {
  margin-bottom: 135px;
}
.news__box {
  width: 362px;
  height: 428px;
  box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  padding: 22px 30px;
  display: flex;
  flex-direction: column;
  margin-top: -58px;
  background-color: #fff;
  z-index: 5;
}
.news__box img {
  margin: 0 auto 20px;
}
.news__box h2 {
  font-family: "InterstatePlus-Bold";
  font-size: 2.4rem;
  color: #272832;
  letter-spacing: -0.01em;
  text-align: center;
  border-bottom: 1px dashed #c6c6c6;
  padding-bottom: 20px;
}
.news__box h2.news__title__lg {
  font-size: 3.2rem;
  border-bottom: 0;
  padding-bottom: 25px;
}
.news__box p.news__simulador__text {
  font-family: Arial, Helvetica, sans-serif;
  color: #393a42;
  line-height: 2.5rem;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 28px;
}
.news__single {
  min-height: 58px;
  border-bottom: 1px dashed #c6c6c6;
  height: auto;
  display: flex;
  align-items: center;
  padding: 18px 0;
}
.news__single span {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.3rem;
  color: #393a42;
  font-weight: 500;
}
.news__box img.news__simulador__img {
  margin: 25px auto 15px;
}
.news__single a {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  color: #393a42;
  font-weight: bold;
}
.news__single--mobile {
  display: none;
}
.rentabilidade__table {
  height: 200px;
  margin-top: 12px;
  padding: 0 4px;
  padding-top: 1px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #c6c6c6;
}
.rentabilidade__table p {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #2b2c35;
  font-size: 1.5rem;
  margin-bottom: 10px;
}
.rentabilidade__data {
  margin-bottom: 6px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.5rem;
  color: #2b2c35;
  display: flex;
  justify-content: space-between;
}
.rentabilidade__data--bold {
  font-weight: bold;
}
/* Vídeos */
.videos {
  margin-bottom: 55px;
}
.videos h1 {
  display: flex;
  width: 100%;
  font-family: "InterstatePlus-Bold";
  font-size: 3.2rem;
  color: #272832;
  letter-spacing: -0.01em;
  justify-content: center;
  margin-bottom: 35px;
}
.videos__box {
  width: 263px;
  width: 246px;
}
.videos__desc p {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #333333;
  line-height: 20px;
  font-size: 1.5rem;
}
.videos__desc span {
  display: none;
}
.videos__desc img {
  margin-bottom: 16px;
  width: 246px;
}
/* Footer */
.top__footer {
  background-color: #33333b;
  height: 230px;
  padding-top: 42px;
}
.footer__menu__mobile {
  display: none;
}
.top__footer__left {
  width: 458px;
  display: flex;
  margin-right: 128px;
}
.footer__column ul {
  display: flex;
  flex-direction: column;
}
.footer__column {
  display: flex;
  flex-direction: column;
  height: 140px;
  margin-right: 90px;
}
.footer__column:last-of-type {
  margin-right: 0;
}
.footer__column li a {
  font-family: Arial, Helvetica, sans-serif;
  color: #9898a7;
  font-size: 1.4rem;
  line-height: 30px;
  font-weight: 500;
}
.top__footer__left ul span {
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff;
  font-size: 1.4rem;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  display: block;
}
.footer__logo {
  margin-right: 44px;
}
.top__footer__right {
  width: 555px;
  position: relative;
}
.top__footer__right:before {
  content: "";
  height: 28px;
  width: 1px;
  background-color: #9899a8;
  position: absolute;
  right: 11px;
  top: 9px;
}
.top__footer__right:after {
  content: "";
  height: 28px;
  width: 1px;
  background-color: #9899a8;
  position: absolute;
  left: 11px;
  top: 9px;
}
.top__footer__right h2 {
  font-family: Arial, Helvetica, sans-serif;
  color: #ffffff;
  font-weight: bold;
  font-size: 1.4rem;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 25px;
}
.top__footer__right h2:before {
  content: "";
  height: 1px;
  width: 130px;
  background-color: #9899a8;
  display: inline-block;
  margin-right: 6px;
  vertical-align: middle;
}
.top__footer__right h2:after {
  content: "";
  height: 1px;
  width: 130px;
  background-color: #9899a8;
  display: inline-block;
  margin-left: 6px;
  vertical-align: middle;
}
.top__footer__phones {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.top__footer__phone,
.top__footer__mail {
  display: flex;
  flex-direction: column;
  width: 33.3%;
}
.top__footer__phone a,
.top__footer__mail a {
  font-size: 1.7rem;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
}
.top__footer__phone a span,
.top__footer__mail a span {
  display: block;
  font-size: 1.2rem;
  margin-top: 5px;
}
.top__footer__phone img,
.top__footer__mail img {
  margin: 0 auto 8px;
  display: block;
}
.sub__footer {
  background-color: #202027;
  height: 45px;
  display: flex;
  align-items: center;
}
.sub__footer p {
  font-size: 1.3rem;
  color: #fff;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center;
}
.glide-2 .btn-glide-left {
  position: absolute;
  top: 45px; 
  left: -65px;
  margin: 0;
}
.glide-2 .btn-glide-right {
  position: absolute;
  margin: 0;
  top: 45px;
  right: -55px;
}

.overlay__iframe video {
  background: #1f1f1f;
}


@media (min-width: 1170px) and (max-width: 1410px) {
  .btn-glide-right {
    margin-right: 0;
  }
  .btn-glide-left {
    margin-left: 0;
  }
}
@media (min-width: 768px) and (max-width: 1169px) {
  .grid {
    width: 768px;
    padding: 0 10px;
  }
  .grid__footer {
    flex-direction: column;
    padding: 0;
    width: 100%;
  }
  .grid__slide {
    padding-top: 102px;
  }
  .grid__header {
    flex-direction: column;
  }

  .videos .grid {
    width: 768px;
  }

  .bg-menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    opacity: 0.1;
    display: none;
  }
  .chk--mobile:checked ~ .header__nav > .bg-menu-mobile {
    display: block;
  }

  .btn__vervideo--azul {
    background-color: #00bef2;
    color: #fff;
  }
  .btn__vervideo--roxo {
    background-color: #6c2a80;
    color: #fff;
  }
  .btn__vervideo--amarelo {
    color: #393a42;
    background-color: #efcb13;
  }

  .glide-2 .glide__controls {
    display: none;
  }

  /* Header */
  header {
    position: relative;
  }
  header:after {
    position: absolute;
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #dfdfdf;
    bottom: 63px;
  }
  .header__nav__mobile {
    display: block;
    width: 24px;
    position: absolute;
    top: 22px;
    z-index: 9;
    cursor: pointer;
  }
  .header__nav__mobile span {
    height: 4px;
    width: 24px;
    background-color: #393a42;
    display: block;
    margin-bottom: 4px;
  }
  .header__logo img {
    width: auto;
    height: auto;
  }
  .chk--mobile {
    display: none;
  }
  .chk--mobile:checked ~ .header__nav > .header__nav__main {
    left: 0 !important;
  }
  .header__nav {
    width: 100%;
    padding: 0;
    height: 130px;
  }
  .header__nav__main {
    position: fixed;
    left: 0;
    top: 0;
    width: 376px;
    flex-direction: column;
    background-color: #efefef;
    box-shadow: -1px -10px 30.1px 4.9px rgba(0, 0, 0, 0.97);
    height: 100%;
    justify-content: inherit;
    left: -400px;
    transition: all 1s ease;
    z-index: 10;
  }
  .header__nav__main li {
    height: 60px;
    width: 100%;
    padding-left: 15px;
    border-bottom: 1px solid #dadada;
  }
  .header__nav__main li a {
    height: 60px;
    width: 100%;
    display: flex;
    font-size: 2rem;
    font-weight: 700;
    align-items: center;
  }
  .header___nav__title__mob {
    height: 65px;
    background-color: #fff;
    display: flex;
    padding-left: 12px;
  }
  .header___nav__title__mob img {
    margin-right: 20px;
  }
  .header___nav__title__mob button {
    font-family: "InterstatePlus-Light";
    font-weight: 300;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #2b2c35;
    letter-spacing: -0.01em;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  .header__nav__buttons {
    width: 374px;
    left: 50%;
    margin-left: auto;
    margin-right: 6px;
    bottom: 10px;
  }
  .header__nav__top {
    width: 100px;
    display: flex;
    justify-content: space-between;
  }
  .header__nav__top ul {
    align-items: center;
  }
  .header__nav__top ul li a:after {
    height: 28px;
    width: 1px;
    content: "";
    display: flex;
    margin-left: 3px;
    background-color: #d6d6d6;
  }
  .header__nav__top ul li:last-of-type a:after {
    display: none;
  }
  .header__nav__top ul li a span {
    font-size: 0;
  }
  .header__logo {
    width: 768px;
    padding: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -384px;
    left: 50%;
    top: 8px;
    a {
      z-index: 9;
    }
  }
  .header__logo img {
    height: 47px;
  }

  /* Slider */
  .slider {
    height: 534px;
  }
  .glide .glide__controls {
    width: 768px;
    margin-left: -384px;
    z-index: 5;
  }
  .btn-glide-right {
    margin-right: 0;
  }
  .btn-glide-left {
    margin-left: 0;
  }
  .grid__slide {
    padding-top: 92px;
  }
  .grid__slide img {
    margin-left: 50px;
    margin-top: 10px;
  }
  .slide__principal h1 {
    font-size: 4.5rem;
    width: 288px;
  }
  .slide__principal h1 br {
    display: none;
  }
  .glide__bullets {
    bottom: 80px;
  }
  .grid__slide img {
    margin-right: 15px;
  }
  .grid__slide .img-boneco-1 {
    margin-right: 38px;
  }
  .glide-2 .glide__slide {
    width: 364px !important;
    margin-left: 4px;
  }
  .glide-2 .glide__slides {
    flex-wrap: wrap;
    padding-top: 5px;
  }
  .glide-2 .glide__slide:nth-of-type(2) {
    margin-right: 0 !important;
  }
  .glide-2 .glide__slide:nth-of-type(3) {
    margin-left: 4px !important;
  }
  .glide-2 .glide__slide:nth-of-type(5) {
    margin-left: 4px !important;
    margin-right: 7.5px;
  } 
  .grid__helper__video {
    padding: 0 8px;
  }
  .overlay__iframe {
    width: 700px;
    margin-left: -350px;
  }

  .glide .glide__slide:nth-of-type(1),
  .glide .glide__slide:nth-of-type(2),
  .glide .glide__slide:nth-of-type(3) {
    background-image: none !important;
    position: relative;
    background-color: #efefef;
    z-index: 5;
  }
  .glide .glide__slide:nth-of-type(1):after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    bottom: 0;
    left: inherit;
    right: 0;
    z-index: -1;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 346px 768px;
    border-color: transparent transparent #f5d240 transparent;
  }
  .glide .glide__slide:nth-of-type(2):after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    left: inherit;
    right: 0;
    bottom: 0;
    z-index: -1;
    border-style: solid;
    border-width: 0 0 346px 768px;
    border-color: transparent transparent #6c2a80 transparent;
  }
  .glide .glide__slide:nth-of-type(3):after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    bottom: 0;
    left: inherit;
    right: 0;
    z-index: -1;
    border-style: solid;
    border-width: 0 0 346px 768px;
    border-color: transparent transparent #90e2f8 transparent;
  }

  /* Botões */
  .btn__restrita {
    width: 179px;
    height: 40px;
  }
  .btn__simulador {
    width: 179px;
    height: 40px;
    border: 2px solid #323232;
    color: #323232;
    background-color: #fff;
  }
  .btn__vervideo {
    margin-top: 25px;
    width: 237px;
    height: 59px;
    font-size: 2rem;
    border-radius: 40px;
  }
  .btn__simulacao {
    margin-left: auto;
    height: 45px;
    width: 293px;
    margin-right: 0;
    font-size: 2rem;
    color: #fff;
    border: 0;
    background-color: #00bef2;
    margin-top: 35px;
    margin-right: 10px;
  }
  .btn__leia {
    width: 158px;
    height: 45px;
    border-radius: 5px;
    font-size: 2rem;
    position: absolute;
    bottom: 37px;
    left: 50%;
    margin: 0;
    margin-left: -79px;
  }

  /* News */
  .news {
    margin-bottom: 6px;
  }
  .news__box {
    width: 365px;
    position: relative;
    height: 461px;
    margin-bottom: 34px;
    margin-top: -58px;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 14px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.11);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.11);
  }
  .news__box:nth-of-type(2) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .news__box:last-of-type {
    margin-top: 0;
    width: 100%;
    height: 149px;
    flex-direction: row;
    padding: 20px 8px;
    position: relative;
  }
  .news__box img {
    margin-bottom: 8px;
  }
  .news__box:nth-of-type(1) img {
    height: 40px !important;
    width: 48px !important;
  }
  .news__box:nth-of-type(2) img {
    height: 41px !important;
    width: 63px !important;
  }
  .news__box:nth-of-type(3) img {
    height: 109px !important;
    width: 102px !important;
  }
  .news__box h2 {
    font-size: 1.8rem;
    border-bottom: 0;
  }
  .news__single {
    min-height: 48px;
    padding: 10px 0;
  }
  .news__single:nth-of-type(1) {
    height: 60px;
    padding: 0 10px;
  }
  .news__single:nth-of-type(2),
  .news__single:nth-of-type(3) {
    height: 92px;
  }
  .news__box img.news__simulador__img {
    height: 109px;
    margin: 0;
  }
  .news__box h2.news__title__lg {
    margin-left: 16px;
    margin-top: 15px;
  }
  .news__box p.news__simulador__text {
    position: absolute;
    left: 128px;
    top: 76px;
    font-size: 1.8rem;
    text-align: left;
    line-height: 1.2;
    width: 275px;
  }
  .news__box p.news__simulador__text br {
    display: none;
  }
  .news__single {
    padding-left: 10px;
    padding-right: 10px;
  }
  .news__single--mobile {
    display: flex;
  }
  .rentabilidade__table {
    height: 214px;
    border-bottom: 0;
  }
  .rentabilidade__data {
    margin-bottom: 10px;
  }
  /* Vídeos */
  .videos {
    margin-bottom: 32px;
  }
  .videos__box {
    /* width: 367px; */
    width: 355px;
    padding: 14px 20px;
    height: 342px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.11);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.11);
    margin-bottom: 24px;
  }
  .videos__desc img {
    width: 100%;
    height: 217px;
  }
  .videos__desc p {
    font-size: 2rem;
    line-height: 1.2;
    font-weight: 500;
  }

  /* Footer */
  .top__footer {
    padding-top: 24px;
    height: 440px;
    display: flex;
  }
  .footer__menu__mobile {
    display: none;
  }
  .top__footer__left {
    width: 100%;
    margin: 0;
    flex-direction: column;
    padding-bottom: 42px;
  }
  .footer__logo {
    width: 100%;
    justify-content: center;
    display: flex;
    margin: 0 0 26px 0;
  }
  .footer__column {
    height: inherit;
    margin: 0;
  }
  .footer__column ul {
    flex-direction: row;
    margin: 0 auto;
    margin-bottom: 10px;
  }
  .footer__column li a {
    font-size: 1.5rem;
    color: #fff;
  }
  .footer__column li {
    display: flex;
    align-items: center;
  }
  .footer__column li:after {
    content: "";
    display: inline-block;
    height: 15px;
    width: 1px;
    background-color: #fff;
    margin: 0 12px;
  }
  .footer__column li:last-of-type:after {
    content: none;
  }
  .top__footer__left ul span {
    display: none;
  }
  .top__footer__right {
    width: 100%;
    height: 242px;
    background-color: #292a31;
    padding-top: 50px;
  }
  .top__footer__phones {
    width: 555px;
    margin: 0 auto;
    position: relative;
  }
  .top__footer__right h2 {
    display: flex;
    width: 555px;
    justify-content: center;
    align-items: center;
    margin: 0 auto 24px;
  }
  .top__footer__right:before {
    right: 245px;
    top: 58px;
    content: none;
  }
  .top__footer__right:after {
    left: 245px;
    top: 58px;
    content: none;
  }
  .top__footer__phones:before {
    content: "";
    height: 28px;
    width: 1px;
    background-color: #9899a8;
    position: absolute;
    right: 11px;
    top: -33px;
  }
  .top__footer__phones:after {
    content: "";
    height: 28px;
    width: 1px;
    background-color: #9899a8;
    position: absolute;
    left: 11px;
    top: -33px;
  }
}
@media (max-width: 767px) {
  /* Grid */
  .grid {
    max-width: 300px;
    padding: 0;
  }
  .grid__footer {
    flex-direction: column;
    padding-top: 15px;
    width: 100%;
    max-width: inherit;
    padding: 0;
  }
  .grid__helper__video {
    margin: 0;
    max-width: 100%;
  }
  .grid__slide {
    flex-direction: column;
  }
  .grid__header {
    max-width: 90%;
  }
  .grid__justify {
    max-width: 90%;
  }
  .btn__vervideo--azul {
    background-color: #00bef2;
    color: #fff;
  }
  .btn__vervideo--roxo {
    background-color: #6c2a80;
    color: #fff;
  }
  .btn__vervideo--amarelo {
    color: #393a42;
    background-color: #efcb13;
  }
  .glide-2 .glide__controls {
    display: none;
  }
  .overlay__iframe {
    width: 90%;
    margin-left: 0;
    height: 400px;
    margin-top: -200px;
    left: 5%;
    &--external {
      height: inherit;
    }
  }
  .overlay__bg__text {
    margin-bottom: 20px;
  }
  .rentabilidade__table {
    height: auto;
  }
  /* Header */
  header {
    position: relative;
  }
  header:after {
    position: absolute;
    display: block;
    content: "";
    width: 100%;
    height: 1px;
    background-color: #dfdfdf;
    bottom: 63px;
  }
  .header__nav__mobile {
    display: block;
    width: 24px;
    position: absolute;
    top: 22px;
    z-index: 9;
    cursor: pointer;
  }
  .header__nav__mobile span {
    height: 4px;
    width: 24px;
    background-color: #393a42;
    display: block;
    margin-bottom: 4px;
  }
  .header__logo a {
    z-index: 9;
  }
  .header__logo img {
    width: auto;
    height: auto;
  }
  .chk--mobile {
    display: none;
  }
  .chk--mobile:checked ~ .header__nav > .header__nav__main {
    left: 0 !important;
  }
  .bg-menu-mobile {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    opacity: 0.1;
    display: none;
  }
  .chk--mobile:checked ~ .header__nav > .bg-menu-mobile {
    display: block;
  }
  .header__nav {
    width: 100%;
    padding: 0;
    height: 130px;
  }
  .header__nav__main {
    position: fixed;
    left: 0;
    top: 0;
    width: 268px;
    flex-direction: column;
    background-color: #efefef;
    box-shadow: -1px -10px 30.1px 4.9px rgba(0, 0, 0, 0.97);
    height: 100%;
    justify-content: inherit;
    left: -400px;
    transition: all 1s ease;
    z-index: 10;
  }
  .header__nav__main li {
    height: 52px;
    width: 100%;
    padding-left: 15px;
    border-bottom: 1px solid #dadada;
  }
  .header__nav__main li a {
    height: 52px;
    width: 100%;
    display: flex;
    font-size: 1.6rem;
    font-weight: 700;
    align-items: center;
  }
  .header___nav__title__mob {
    height: 65px;
    background-color: #fff;
    display: flex;
    padding-left: 12px;
  }
  .header___nav__title__mob img {
    margin-right: 20px;
  }
  .header___nav__title__mob button {
    font-family: "InterstatePlus-Light";
    font-weight: 300;
    font-size: 1.4rem;
    text-transform: uppercase;
    color: #2b2c35;
    letter-spacing: -0.01em;
    display: flex;
    align-items: center;
  }
  .header__nav__buttons {
    width: 300px;
    margin-left: auto;
    margin-right: 6px;
    bottom: 10px;
  }
  .header__nav__top {
    width: 100px;
    display: flex;
    justify-content: space-between;
  }
  .header__nav__top ul {
    align-items: center;
  }
  .header__nav__top ul li a:after {
    height: 28px;
    width: 1px;
    content: "";
    display: flex;
    margin-left: 3px;
    background-color: #d6d6d6;
  }
  .header__nav__top ul li:last-of-type a:after {
    display: none;
  }
  .header__nav__top ul li a span {
    font-size: 0;
  }
  .header__logo {
    width: 100%;
    padding: 0;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    top: 8px;
  }
  .header__logo img {
    height: 47px;
  }

  /* Slider */
  .slider {
    height: auto;
  }
  .slide__principal h1 {
    font-size: 3rem;
    text-align: center;
  }
  .slide__principal h1 br {
    display: none;
  }
  .glide .glide__controls {
    width: 100%;
    margin: 0;
    left: 0;
  }
  .glide-2 .glide__controls {
    width: 100%;
    margin: 0;
    left: 0;
  }
  .glide__bullets {
    bottom: 16px;
  }
  .btn-glide-left {
    margin: 0;
  }
  .btn-glide-right {
    margin: 0;
  }
  .btn-glide-left img {
    margin-left: 6px;
    height: 36px;
  }
  .btn-glide-right img {
    margin-right: 6px;
    height: 36px;
  }
  .grid__slide img {
    margin: 20px auto;
    height: 77px;
  }
  .grid__slide .img-boneco-1 {
    height: 179px;
    margin: 0;
    left: 50%;
    display: inline-flex;
    list-style: none;
    transform: translateX(-50%);
    position: absolute;
    bottom: 54px;
  }
  .grid__slide .img-boneco-2 {
    height: 179px;
    margin: 0;
    left: 50%;
    display: inline-flex;
    list-style: none;
    transform: translateX(-50%);
    position: absolute;
    bottom: 54px;
  }
  .grid__slide .btn__vervideo img {
    height: 28px;
    width: 28px;
  }
  .grid__slide .btn__vervideo .img-leia {
    height: 25px;
    width: 15px;
  }
  .glide .glide__slide {
    background-image: none !important;
    background-color: #efefef;
    position: relative;
    z-index: 5;
  }
  .glide .glide__slide:nth-of-type(1):after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    bottom: 0;
    z-index: -1;
    border-style: solid;
    border-width: 0 0 450px 450px;
    border-color: transparent transparent #f5d240 transparent;
  }
  .glide .glide__slide:nth-of-type(2):after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    bottom: 0;
    z-index: -1;
    border-style: solid;
    border-width: 0 0 450px 450px;
    border-color: transparent transparent #6c2a80 transparent;
  }
  .glide .glide__slide:nth-of-type(3):after {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    bottom: 0;
    z-index: -1;
    border-style: solid;
    border-width: 0 0 450px 450px;
    border-color: transparent transparent #90e2f8 transparent;
  }

  /* Botões */
  .btn__external {
    width: 100%;
  }
  .btn__simulador {
    width: 142px;
  }
  .btn__restrita {
    width: 142px;
  }
  .btn__simulacao {
    width: 263px;
    height: 45px;
    border: 0;
    background-color: #00bef2;
    color: #fff;
    position: absolute;
    bottom: 20px;
    font-size: 2rem;
    left: 20px;
  }
  .btn__vervideo {
    width: 292px;
    height: 45px;
    font-size: 2rem;
    margin: 194px auto 0;
  }
  .btn__leia {
    width: 258px;
    height: 40px;
    border-radius: 5px;
    background-color: #fff;
    border: 2px solid #00bef2;
    color: #00bef2;
    font-size: 2rem;
    margin-top: 22px;
  }

  /* News */
  .news__box {
    width: 100%;
    min-height: auto;
    height: auto;
    margin-top: 0;
    margin-bottom: 25px;
    padding: 14px 10px 20px;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.11);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.11);
  }
  .news__box:nth-of-type(1) img {
    width: 48px !important;
    height: 40px !important;
    margin-bottom: 10px;
  }
  .news__box:nth-of-type(2) img {
    margin-bottom: 10px;
  }
  .news__box h2 {
    font-size: 1.8rem;
    border-bottom: none;
    padding-bottom: 15px;
  }
  .news__single--mobile {
    display: flex;
  }
  .news {
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .news__box:nth-of-type(1) {
    display: flex;
    order: 2;
  }
  .news__box:nth-of-type(2) {
    display: flex;
    order: 3;
  }
  .news__box:nth-of-type(3) {
    display: flex;
    order: 1;
    flex-direction: row;
    height: 178px;
    min-height: inherit;
    position: relative;
  }
  .news__box img.news__simulador__img {
    width: 75px !important;
    height: 79px !important;
    margin: 0;
    margin-right: 14px;
  }
  .news__box h2.news__title__lg {
    font-size: 2.2rem;
  }
  .news__box p.news__simulador__text {
    width: 190px;
    font-size: 1.5rem;
    position: absolute;
    bottom: 82px;
    left: 100px;
    text-align: left;
    line-height: 1.2;
    margin: 0;
  }
  .news__box p.news__simulador__text br {
    display: none;
  }
  .news__single {
    height: 60px;
    padding: 0;
  }
  .news__single:nth-of-type(1) {
    height: 38px;
    min-height: auto;
  }
  .rentabilidade__data {
    margin-bottom: 10px;
  }
  .rentabilidade__table {
    border-bottom: 0;
    padding-bottom: 0;
  }

  /* Vídeos */
  .videos {
    position: relative;
  }
  .videos:after {
    position: absolute;
    content: "";
    height: 80px;
    z-index: -1;
    width: 100%;
    bottom: -54px;
    background: #e8eff5;
  }
  .videos__box {
    width: 92%;
    min-height: 301px;
    height: auto;
    -webkit-box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.11);
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.11);
    padding: 12px 15px 20px;
    margin: 4px 10px;
    background-color: #fff;
  }
  .videos__desc img {
    width: 100%;
    margin-bottom: 20px;
  }
  .videos__desc span {
    color: #333333;
    font-size: 1.3rem;
    font-family: "InterstatePlus-Regular";
    margin-bottom: 12px;
    display: block;
  }
  .videos__desc p {
    font-size: 1.5rem;
    font-weight: 500;
    padding-left: 4px;
    width: 190px;
  }
  .videos h1 {
    justify-content: flex-start;
    padding: 0 8px;
  }
  .grid__helper__video {
    padding: 0;
    margin: 0;
    padding-left: 8px;
    max-width: 100% !important;
  }
  /* Footer */
  .top__footer {
    height: auto;
    padding: 0;
  }
  .footer__menu__mobile {
    display: flex;
    height: 46px;
    background-color: #393a42;
    font-family: Arial, Helvetica, sans-serif;
    color: #fff;
    font-size: 1.3rem;
    text-transform: uppercase;
    align-items: center;
    padding: 0 14px;
    position: relative;
    cursor: pointer;
    border-bottom: 1px solid #5b5c64;
  }
  .footer__menu__mobile img {
    position: absolute;
    top: 18px;
    right: 16px;
  }
  .top__footer__left {
    width: 100%;
    margin: 0;
    flex-direction: column;
  }
  .footer__column {
    height: auto;
    margin: 0;
    display: none;
    padding: 0 13px;
  }
  .footer__column.active {
    display: block;
    background-color: #393a42;
  }
  .footer__column:last-of-type {
    padding-bottom: 13px;
  }
  .footer__logo {
    display: none;
  }
  .footer__column li a {
    color: #fff;
    width: 100%;
    display: block;
  }
  .top__footer__phones {
    flex-direction: column;
  }
  .top__footer__phone,
  .top__footer__mail {
    flex-direction: row;
    width: 100%;
  }
  .top__footer__right h2 {
    width: 170px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 30px;
  }
  .top__footer__right h2:before {
    width: 45px;
    position: absolute;
    left: 11px;
    top: 8px;
  }
  .top__footer__right h2:after {
    width: 45px;
    position: absolute;
    right: 11px;
    top: 8px;
  }
  .top__footer__phone img,
  .top__footer__mail img {
    margin: 0;
  }
  .top__footer__phone img,
  .top__footer__mail img {
    display: inline;
    margin-right: 18px;
  }
  .top__footer__phone a span,
  .top__footer__mail a span {
    position: absolute;
    bottom: 10px;
    left: 59px;
    width: 200px;
    text-align: left;
    font-weight: 500;
  }
  .top__footer__right {
    width: 100%;
    height: auto;
    margin-top: 35px;
  }
  .top__footer__phone,
  .top__footer__mail {
    justify-content: center;
  }
  .top__footer__phone a,
  .top__footer__mail a {
    display: flex;
    align-items: flex-start;
    position: relative;
    width: 232px;
    height: 46px;
    margin-bottom: 18px;
  }
  .top__footer__mail a {
    margin-bottom: 40px;
  }
  .top__footer__left ul span {
    display: none;
  }
  .btn__simulacao {
    left: 50%;
    margin-left: -131px;
  }
  .videos__box {
    // width: 300px;
    // height: 317px;
    width: 250px;
    height: 301px;
  }
  .glide-2 .glide__slide {
    // width: 325px !important;
    /* width: 275px !important; */
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  .grid__helper__video {
    max-width: 95% !important;
    margin: 0 auto;
  }
  .overlay__iframe {
    height: 90%;
    top: 5%;
    margin-top: 0;
    &--external {
      height: inherit;
    }
  }
  .btn__external {
    width: 300px;
    margin: 0 auto;
  }
  .glide .glide__slide:nth-of-type(1):after {
    right: 0;
  }
  .glide .glide__slide:nth-of-type(2):after {
    right: 0;
  }
  .glide .glide__slide:nth-of-type(3):after {
    right: 0;
  }
  .grid__justify {
    max-width: 90%;
  }
  .news__box p.news__simulador__text {
    width: 370px;
    bottom: 98px;
  }
  .btn__simulacao {
    left: 50%;
    margin-left: -131px;
  }
  .videos__box {
    width: 300px;
    height: 317px;
  }
  .glide-2 .glide__slide {
    width: 325px !important;
  }
}
@media (min-width: 450px) and (max-width: 767px) {
  .glide .glide__slide:nth-of-type(1):after {
    right: 0;
  }
  .glide .glide__slide:nth-of-type(2):after {
    right: 0;
  }
  .glide .glide__slide:nth-of-type(3):after {
    right: 0;
  }
}
