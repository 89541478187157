@font-face {
  font-family: "InterstatePlus-Light";
  src: url("../fonts/InterstatePlus-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "InterstatePlus-Bold";
  src: url("../fonts/InterstatePlus-Bold.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "InterstatePlus-Regular";
  src: url("../fonts/InterstatePlus-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
