.main__noticias {
  display: flex;
  flex-direction: column;
}
.noticias__content {
  .noticias__wrapper {
    margin-top: 46px;
    /* display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100vw;
    max-height: 850px; */
    margin-bottom: 64px;
  }
  .noticia:last-of-type {
    margin-bottom: 0;
  }
  .noticia:nth-last-child(-n + 2) {
    margin-bottom: 0;
  }
  .noticia:nth-of-type(n + 9) {
    margin-right: 0;
  }
  .noticia {
    width: 360px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 18px;
    margin-bottom: 36px;
    /* margin-right: 25px; */
    &__date {
      font-size: 1.3rem;
      color: #393a42;
      font-family: Arial, Helvetica, sans-serif;
      letter-spacing: -0.025em;
      line-height: 1.2;
      margin-bottom: 18px;
      display: block;
      font-weight: 400;
    }
    &__title {
      font-size: 2rem;
      color: #272832;
      font-family: Arial, Helvetica, sans-serif;
      letter-spacing: -0.025em;
      line-height: 1.2;
      font-weight: 700;
      margin-bottom: 20px;
    }
    &__link {
      font-size: 1.4rem;
      color: #00bef2;
      line-height: 1.2;
      font-family: "InterstatePlus-Bold";
      text-transform: uppercase;
      text-decoration: underline;
    }
  }
}
@media (min-width: 768px) and (max-width: 1169px) {
  .noticias__content {
    .noticias__wrapper {
      min-height: 1280px;
    }
    .noticia:nth-of-type(n + 6) {
      margin-right: 0;
    }
    .noticia:nth-of-type(6) {
      margin-bottom: 0;
    }
    .noticia:nth-of-type(12) {
      margin-bottom: 0;
    }
    .noticia {
      margin-bottom: 28px !important;
    }
  }
}

@media (max-width: 767px) {
  .noticias__content {
    .grid {
      max-width: 90%;
    }
    .noticias__wrapper {
      margin-top: 16px;
      width: 100%;
      flex-wrap: inherit;
      height: inherit;
      max-height: inherit;
    }
    .noticia {
      width: 100%;
      padding: 18px 8px;
      margin-bottom: 18px !important;
      &__date {
        margin-bottom: 10px;
      }
      &__title {
        font-size: 1.7rem;
        margin-bottom: 15px;
      }
    }
  }
}
