img {
    border: 0 !important;
}

.ico-chatbot {
    position: fixed;
    bottom: 0;
    right: 1%;
    z-index: 9998;
}

.chat-window {
    background-color: #FFF;
    background: url("../../dist/img/chatbot/ico-top-chat.jpg") left top no-repeat;
    border-radius: 6px 6px 0 0;
    bottom: -100%;
    height: 345px;
    overflow: hidden;
    position: fixed;
    right: 10px;
    width: 260px;
    z-index: 9999;

    -webkit-transition: all ease 1s;
    -moz-transition: all ease 1s;
    -o-transition: all ease 1s;
    -ms-transition: all ease 1s;
    transition: all ease 1s;

    -webkit-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.4);
    -moz-box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.4);
    box-shadow: 0px 0px 50px 0px rgba(0,0,0,0.4);

    h2 {
        color: #353841;
        font-family: Arial,Helvetica,sans-serif;
        font-size: 19px;
        padding: 22px 0 0 58px;
    }

    .ico-chat-close {
        position: absolute;
        right: 11px;
        top: 13px;
    }

    .content-chatbot {
        bottom: 0;
        height: 280px;
        left: 0;
        overflow: hidden;
        position: absolute;
        width: 100%;
    }
}

.chat-window.active {
   bottom: 0;

   -webkit-transition: all ease .5;
    -moz-transition: all ease .5;
    -o-transition: all ease .5;
    -ms-transition: all ease .5;
    transition: all ease .5;
}