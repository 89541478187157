.main__lpb {
  display: flex;
  flex-direction: column;
}
.grid__lpb__content {
  display: flex;
  flex-direction: column;
}
.lpb__content {
  margin-top: 55px;
  padding-bottom: 92px;
}
.lpb__option__mobile {
  display: none;
}
.lpb__content__desc {
  display: flex;
  justify-content: space-between;
  input[type="radio"] {
    display: none;
  }
  #item01Content:checked ~ #content01 {
    display: block;
    opacity: 1;
  }
  #item01Content:checked ~ .lpb__options li:nth-of-type(1) {
    border-bottom: 0;
  }
  #item02Content:checked ~ .lpb__options li:nth-of-type(2) {
    border-bottom: 0;
  }
  #item02Content:checked ~ .lpb__options li:nth-of-type(1) {
    border-bottom: 0;
  }
  #item03Content:checked ~ .lpb__options li:nth-of-type(3) {
    border-bottom: 0;
  }
  #item03Content:checked ~ .lpb__options li:nth-of-type(2) {
    border-bottom: 0;
  }
  #item04Content:checked ~ .lpb__options li:nth-of-type(4) {
    border-bottom: 0;
  }
  #item04Content:checked ~ .lpb__options li:nth-of-type(3) {
    border-bottom: 0;
  }
  #item05Content:checked ~ .lpb__options li:nth-of-type(5) {
    border-bottom: 0;
  }
  #item05Content:checked ~ .lpb__options li:nth-of-type(4) {
    border-bottom: 0;
  }
  #item06Content:checked ~ .lpb__options li:nth-of-type(6) {
    border-bottom: 0;
  }
  #item06Content:checked ~ .lpb__options li:nth-of-type(5) {
    border-bottom: 0;
  }
  #item01Content:checked ~ .lpb__options li:nth-of-type(1) label {
    background-color: #f5d240;
    border-bottom: 0;
    border-radius: 5px;
    font-weight: 700;
    &:after {
      content: "";
      transform: rotate(-90deg);
      position: absolute;
      right: 16px;
      display: block;
      width: 13px;
      top: 20px;
      height: 10px;
      background-size: 13px;
      background-image: url("../img/arrow-down-sign-to-navigate.svg");
      background-repeat: no-repeat;
    }
  }
  #item02Content:checked ~ #content02 {
    display: block;
    opacity: 1;
  }
  #item02Content:checked ~ .lpb__options li:nth-of-type(2) label {
    background-color: #f5d240;
    border-bottom: 0;
    border-radius: 5px;
    font-weight: 700;
    &:after {
      content: "";
      transform: rotate(-90deg);
      position: absolute;
      right: 16px;
      display: block;
      top: 20px;
      width: 13px;
      height: 10px;
      background-size: 13px;
      background-image: url("../img/arrow-down-sign-to-navigate.svg");
      background-repeat: no-repeat;
    }
  }
  #item03Content:checked ~ #content03 {
    display: block;
    opacity: 1;
  }
  #item03Content:checked ~ .lpb__options li:nth-of-type(3) label {
    background-color: #f5d240;
    border-bottom: 0;
    border-radius: 5px;
    font-weight: 700;
    &:after {
      content: "";
      transform: rotate(-90deg);
      position: absolute;
      right: 16px;
      display: block;
      top: 20px;
      width: 13px;
      height: 10px;
      background-size: 13px;
      background-image: url("../img/arrow-down-sign-to-navigate.svg");
      background-repeat: no-repeat;
    }
  }
  #item04Content:checked ~ #content04 {
    display: block;
    opacity: 1;
  }
  #item04Content:checked ~ .lpb__options li:nth-of-type(4) label {
    background-color: #f5d240;
    border-bottom: 0;
    border-radius: 5px;
    font-weight: 700;
    &:after {
      content: "";
      transform: rotate(-90deg);
      position: absolute;
      right: 16px;
      display: block;
      top: 20px;
      width: 13px;
      height: 10px;
      background-size: 13px;
      background-image: url("../img/arrow-down-sign-to-navigate.svg");
      background-repeat: no-repeat;
    }
  }
  #item05Content:checked ~ #content05 {
    display: block;
    opacity: 1;
  }
  #item05Content:checked ~ .lpb__options li:nth-of-type(5) label {
    background-color: #f5d240;
    border-bottom: 0;
    border-radius: 5px;
    font-weight: 700;
    &:after {
      content: "";
      transform: rotate(-90deg);
      position: absolute;
      right: 16px;
      display: block;
      top: 20px;
      width: 13px;
      height: 10px;
      background-size: 13px;
      background-image: url("../img/arrow-down-sign-to-navigate.svg");
      background-repeat: no-repeat;
    }
  }
  #content06 {
    position: relative;
  }
  #item06Content:checked ~ #content06 {
    display: block;
    opacity: 1;
  }
  #item06Content:checked ~ .lpb__options li:nth-of-type(6) label {
    background-color: #f5d240;
    border-bottom: 0;
    border-radius: 5px;
    font-weight: 700;
    &:after {
      content: "";
      transform: rotate(-90deg);
      position: absolute;
      right: 16px;
      display: block;
      top: 20px;
      width: 13px;
      height: 10px;
      background-size: 13px;
      background-image: url("../img/arrow-down-sign-to-navigate.svg");
      background-repeat: no-repeat;
    }
  }
}
.lpb__options {
  width: 264px;
  li {
    height: 50px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e1e1e1;
    margin-bottom: 0;
    label {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1.6rem;
      color: #272832;
      letter-spacing: -0.01em;
      font-weight: 500;
      padding-left: 18px;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      line-height: 1.2;
      &:after {
        content: "";
        transform: rotate(-90deg);
        position: absolute;
        right: 16px;
        top: 20px;
        display: block;
        width: 13px;
        height: 10px;
        background-size: 13px;
        background-image: url("../img/arrow-down-sign-to-navigate-grey.svg");
        background-repeat: no-repeat;
      }
    }
  }
}
.lpb__options__content {
  width: 846px;
  display: none;
  opacity: 0;
  h3 {
    color: #272832;
    font-family: "InterstatePlus-Bold";
    font-size: 3.2rem;
    letter-spacing: -0.01em;
    margin-bottom: 25px;
  }
  h4 {
    color: #393a42;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 2.2rem;
    line-height: 2.6rem;
    margin-bottom: 25px;
  }
  h5 {
    font-family: Arial, Helvetica, sans-serif;
    color: #333333;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
  p {
    font-size: 1.6rem;
    color: #393a42;
    line-height: 2.3rem;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    margin-bottom: 22px;
  }
}
@media (min-width: 768px) and (max-width: 1169px) {
  .lpb__content__desc {
    flex-direction: column;
  }
  .lpb__options {
    width: 100%;
    display: flex;
    margin-bottom: 38px;
    &__content {
      width: 100%;
      h4 {
        font-size: 2rem;
        line-height: 2.6rem;
      }
      p {
        font-size: 1.5rem;
        line-height: 1.2;
        margin-bottom: 16px;
      }
    }
    ul {
      display: flex;
      width: 100%;
      border-bottom: 1px solid #e1e1e1;
      li {
        width: 185px;
        height: 63px;
        margin-right: 4px;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        margin-bottom: 0;
        border-bottom: 0;
        label {
          border-top-right-radius: 5px;
          border-top-left-radius: 5px;
          border-bottom-right-radius: 0 !important;
          border-bottom-left-radius: 0 !important;
          padding: 0;
          justify-content: center;
          border: 1px solid #e1e1e1;
          border-bottom: 0;
          &:after {
            content: none !important;
          }
          &.active {
            border: 0;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;
          }
        }
      }
      li:last-of-type {
        margin-right: 0;
      }
    }
  }
  .radio-select:nth-of-type(1) ~ .lpb__options li:nth-of-type(1) label {
    border: 1px solid #e1e1e1;
    border-bottom: 0;
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    -webkit-justify-content: center;
    justify-content: center;
    padding: 0;
    background-color: #fff !important;
    &:after {
      content: "";
      transform: rotate(-90deg);
      position: absolute;
      right: 16px;
      display: block;
      width: 13px;
      height: 10px;
      background-size: 13px;
      background-image: url("../img/arrow-down-sign-to-navigate.svg");
      background-repeat: no-repeat;
    }
  }
  .radio-select:nth-of-type(1):checked ~ .lpb__options li:nth-of-type(1) label {
    background-color: #f5d240 !important;
  }
}

@media (max-width: 767px) {
  .lpb__content {
    margin-top: 42px;
  }
  .lpb__option__mobile {
    display: flex;
    align-items: center;
    border-radius: 5px;
    font-weight: 700;
    border: 1px solid #333;
    height: 43px;
    width: 100%;
    background-color: #fff;
    color: #272832;
    cursor: pointer;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 1.6rem;
    letter-spacing: -0.01em;
    line-height: 1.2;
    padding-left: 18px;
    position: relative;
    &:after {
      background-image: url(../img/arrow-down-sign-to-navigate.svg);
      background-repeat: no-repeat;
      background-size: 13px;
      content: "";
      display: block;
      height: 10px;
      top: 15px;
      position: absolute;
      right: 16px;
      transform: rotate(0deg);
      width: 13px;
    }
    &.open {
      border-bottom: 1px solid #cccccc;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .lbp__options__helper {
    overflow: hidden;
    border: 1px solid #333;
    border-top: 0;
    display: none;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 4px;
    &.open {
      display: block;
    }
  }
  .grid__lpb__content {
    max-width: 90%;
  }
  .lpb__content__desc {
    flex-direction: column;
  }
  .lpb__content__desc
    .radio-select:nth-of-type(1):checked
    ~ .lpb__options
    li:nth-of-type(1) {
    display: none;
  }
  .lpb__content__desc
    .radio-select:nth-of-type(2):checked
    ~ .lpb__options
    li:nth-of-type(2) {
    display: none;
  }
  .lpb__content__desc
    .radio-select:nth-of-type(3):checked
    ~ .lpb__options
    li:nth-of-type(3) {
    display: none;
  }
  .lpb__content__desc
    .radio-select:nth-of-type(4):checked
    ~ .lpb__options
    li:nth-of-type(4) {
    display: none;
  }
  .lpb__options {
    width: 100%;
    li {
      border-bottom: 0;
      margin-bottom: 0;
      height: 54px;
      border: 0;
      &.open {
        display: block;
        label {
          display: flex;
        }
      }
      img {
        transform: none;
      }
      label {
        background-color: #fff !important;
        &:after {
          content: none;
        }
        &.active {
          border: none;
          background: #fff !important;
        }
      }
    }
  }
  .lpb__options__content {
    width: 100%;
    h3 {
      font-size: 2.4rem;
      padding: 0 8px;
      margin-bottom: 20px;
      margin-top: 22px;
    }
    h4 {
      font-size: 1.7rem;
      line-height: 1.2;
      padding: 0 8px;
      margin-bottom: 20px;
      br {
        display: none;
      }
    }
    h5 {
      font-size: 1.6rem;
      line-height: 2.2rem;
      padding: 0 8px;
    }
    p {
      font-size: 1.5rem !important;
      line-height: 1.2 !important;
      padding: 0 8px;
    }
  }
}
@media (max-width: 767px) and (orientation: landscape) {
  .lpb__options__content {
    p {
      font-size: 1.5rem !important;
      line-height: 1.2 !important;
      padding: 0 8px;
    }
  }
}
