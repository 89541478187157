.component {
  &__planos {
    h5 {
      margin-bottom: 20px;
      font-size: 2.2rem;
    }
    &__wrapper {
      justify-content: space-between;
      width: 629px;
      display: flex;
    }
    &__block {
      width: 195px;
    }
    &__list {
      li {
        align-items: center;
        border-bottom: 1px solid #d1d1d1;
        color: #272832;
        display: flex;
        font-family: "InterstatePlus-Regular";
        font-size: 1.8rem;
        font-weight: 500;
        height: 52px;
        letter-spacing: -0.01em;
        margin: 0;
        position: relative;
        a {
          color: #272832;
          align-items: center;
          border-bottom: 1px solid #d1d1d1;
          display: flex;
          font-family: "InterstatePlus-Regular";
          font-size: 1.8rem;
          font-weight: 500;
          height: 52px;
          letter-spacing: -0.01em;
          margin: 0;
          position: relative;
          padding-left: 42px;
        }
        &:before {
          content: url(../img/ico-download-form.png);
          display: inline;
          height: 20px;
          left: 7px;
          position: absolute;
          top: 15px;
          width: 20px;
        }
      }
      &--blue {
        li {
          position: relative;
          &:before {
            content: url(../img/ico-download-form-blue.png);
            display: inline;
            height: 20px;
            left: 7px;
            position: absolute;
            top: 15px;
            width: 20px;
          }
        }
      }
      &--yellow {
        li {
          position: relative;
          &:before {
            content: url(../img/ico-download-form-yellow.png);
            display: inline;
            height: 20px;
            left: 7px;
            position: absolute;
            top: 15px;
            width: 20px;
          }
        }
      }
    }
    &__title {
      align-items: center;
      color: #fff;
      display: flex;
      font-family: "InterstatePlus-Regular";
      font-size: 1.7rem;
      font-weight: 500;
      height: 44px;
      letter-spacing: -0.01em;
      justify-content: center;
      width: 100%;
      margin-bottom: 15px;
      &--purple {
        background-color: #701d82;
      }
      &--blue {
        background-color: #00bef2;
      }
      &--yellow {
        background-color: #f5d240;
      }
    }
  }
  &__relatorios {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 0;
    margin-top: 40px;
    flex-wrap: wrap;
    display: flex;
    .relatorio__box {
      border: 1px solid #e1e1e1;
      height: 396px;
      margin-bottom: 24px;
      padding: 0 10px;
      width: 262px;
      a {
        color: #666;
        font-family: "InterstatePlus-Regular";
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: -0.01em;
        display: inline-block;
        line-height: 1.2;
        text-decoration: underline;
        width: 216px;
      }
      span {
        color: #666;
        font-family: "InterstatePlus-Regular";
        font-size: 1.5rem;
        font-weight: 500;
        letter-spacing: -0.01em;
        display: inline;
        margin-right: 4px;
        position: relative;
        text-decoration: none;
        top: 0;
        vertical-align: top;
      }
    }
    .relatorio__ano {
      color: #272832;
      font-family: "InterstatePlus-Bold";
      font-size: 2.3rem;
      margin-bottom: 10px;
      margin-top: 14px;
      text-align: center;
    }
    .relatorio__img {
      margin-bottom: 18px;
      text-align: center;
    }
    .relatorio__link {
      display: block;
      margin-bottom: 14px;
    }
  }
  &__table {
    width: 100%;
    display: flex;
    margin-bottom: 40px;
    .table__content {
      width: 456px;
      display: flex;
      .content__side {
        display: flex;
        flex-direction: column;
        height: 100%;
        .title__side {
          display: flex;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 1.4rem;
          font-weight: 500;
          padding-left: 16px;
          width: 100%;
          align-items: center;
          border-bottom: 1px solid #fff;
          background-color: #1bbcef;
          color: #fff;
          height: 41px;
        }
        span {
          align-items: center;
          border-bottom: 1px solid #fff;
          display: flex;
          font-family: Arial, Helvetica, sans-serif;
          font-size: 1.4rem;
          font-weight: 500;
          padding-left: 16px;
          width: 100%;
          color: #272832;
          height: 42px;
          margin: 0;
        }
        span:nth-child(2n) {
          background-color: #efefef;
        }
        span:nth-child(odd) {
          background-color: #f6f6f6;
        }
      }
      .content__left {
        border-right: 2px solid #fff;
        width: 359px;
        .title__left {
        }
      }
      .content__right {
        height: 100%;
        width: 97px;
      }
    }
    &--3-column {
      .table__content {
        .content__side {
          .title__side {
            min-height: 60px;
            padding: 0 12px 0 10px;
          }
        }
        .content__left {
          width: 205px;
        }
        .content__mid {
          width: 103px;
          border-right: 2px solid #fff;
        }
        .content__right {
          width: 146px;
        }
      }
    }
    &--3-split {
      .table__content {
        .content__side {
          .title__side {
            padding: 10px;
            text-align: center;
            justify-content: center;
          }
          span {
            padding: 10px;
            text-align: center;
            justify-content: center;
          }
        }
        .content__left {
          width: 20%;
        }
        .content__mid {
          width: 40%;
        }
        .content__right {
          width: 40%;
        }
        .content__split {
          display: flex;
          width: 100%;
        }
        .content__split__side:first-of-type {
          border-right: 2px solid #fff;
        }
        .content__split__side {
          display: flex;
          width: 50%;
          flex-direction: column;
          span:nth-child(odd) {
            background-color: #efefef;
          }
          span:nth-child(2n) {
            background-color: #f6f6f6;
          }
        }
      }
    }
    &--regras {
      .table__content {
        .content__left {
          width: 80px;
        }
        .content__mid {
          width: 200px;
        }
        .content__right {
          width: 200px;
        }
      }
    }
    &--4-column {
      .table__content {
        flex-direction: column;
        .content__line {
          display: flex;
          width: 100%;
          .title__line {
            align-items: center;
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
            display: flex;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1.4rem;
            font-weight: 500;
            justify-content: center;
            line-height: 1.4;
            margin: 0;
            min-height: 42px;
            padding: 10px;
            text-align: center;
            background-color: #1bbcef;
            color: #fff;
          }
          .title__line:first-of-type {
            width: 20%;
          }
          .title__line:nth-of-type(2) {
            width: 20%;
          }
          .title__line:nth-of-type(3) {
            width: 30%;
          }
          .title__line:last-of-type {
            width: 30%;
          }
          span {
            align-items: center;
            border-bottom: 1px solid #fff;
            display: flex;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1.4rem;
            font-weight: 500;
            padding-left: 16px;
            width: 100%;
            color: #272832;
            min-height: 42px;
            margin: 0;
            justify-content: center;
            line-height: 1.4;
            text-align: center;
            padding: 10px;
            border-right: 1px solid #fff;
          }
          span:first-of-type {
            width: 20%;
          }
          span:nth-of-type(2) {
            width: 20%;
          }
          span:nth-of-type(3) {
            width: 30%;
          }
          span:last-of-type {
            width: 30%;
            border-right: 0;
          }
        }
        .content__line:nth-child(odd) {
          background-color: #efefef;
        }
        .content__line:nth-child(2n) {
          background-color: #f6f6f6;
        }
      }
    }
    &--full {
      .table__content {
        flex-direction: column;
        .content__line {
          display: flex;
          width: 100%;
          span {
            align-items: center;
            border-bottom: 1px solid #fff;
            display: flex;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1.4rem;
            font-weight: 500;
            padding-left: 16px;
            width: 100%;
            color: #272832;
            min-height: 42px;
            margin: 0;
            justify-content: center;
            line-height: 1.4;
            text-align: center;
            padding: 10px;
          }
          span:first-of-type {
            width: 30%;
            border-right: 1px solid #fff;
          }
          span:last-of-type {
            width: 70%;
          }
        }
        .content__line:nth-child(odd) {
          background-color: #efefef;
        }
        .content__line:nth-child(2n) {
          background-color: #f6f6f6;
        }
      }
    }
    &--full-split {
      .table__content {
        flex-direction: column;
        .content__line {
          display: flex;
          width: 100%;
          .title__line {
            align-items: center;
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
            display: flex;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1.4rem;
            font-weight: 500;
            justify-content: center;
            line-height: 1.4;
            margin: 0;
            min-height: 42px;
            padding: 10px;
            text-align: center;
            background-color: #1bbcef;
            color: #fff;
          }
          .title__line:first-of-type {
            width: 12%;
          }
          .title__line:nth-of-type(2) {
            width: 44%;
          }
          .title__line:last-of-type {
            width: 44%;
          }
          span {
            align-items: center;
            border-bottom: 1px solid #fff;
            display: flex;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1.4rem;
            font-weight: 500;
            padding-left: 16px;
            width: 100%;
            color: #272832;
            min-height: 42px;
            margin: 0;
            justify-content: center;
            line-height: 1.4;
            text-align: center;
            padding: 10px;
            border-right: 1px solid #fff;
          }
          span:first-of-type {
            width: 12%;
          }
          span:nth-of-type(2) {
            width: 22%;
          }
          span:nth-of-type(3) {
            width: 22%;
          }
          span:nth-of-type(4) {
            width: 22%;
          }
          span:last-of-type {
            width: 22%;
            border-right: 0;
          }
        }
        .content__line:nth-child(odd) {
          background-color: #efefef;
        }
        .content__line:nth-child(2n) {
          background-color: #f6f6f6;
        }
      }
    }
    &--full-3 {
      .table__content {
        flex-direction: column;
        .content__line {
          display: flex;
          width: 100%;
          .title__line {
            align-items: center;
            border-bottom: 1px solid #fff;
            border-right: 1px solid #fff;
            display: flex;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1.4rem;
            font-weight: 500;
            justify-content: center;
            line-height: 1.4;
            margin: 0;
            min-height: 42px;
            padding: 10px;
            text-align: center;
            background-color: #1bbcef;
            color: #fff;
          }
          .title__line:first-of-type {
            width: 50%;
          }
          .title__line:nth-of-type(2) {
            width: 25%;
          }
          .title__line:last-of-type {
            width: 25%;
          }
          span {
            align-items: center;
            border-bottom: 1px solid #fff;
            display: flex;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1.4rem;
            font-weight: 500;
            padding-left: 16px;
            width: 100%;
            color: #272832;
            min-height: 42px;
            margin: 0;
            justify-content: center;
            line-height: 1.4;
            text-align: center;
            padding: 10px;
            border-right: 1px solid #fff;
            flex-direction: column;
          }
          span:first-of-type {
            width: 50%;
          }
          span:nth-of-type(2) {
            width: 25%;
          }
          span:last-of-type {
            width: 25%;
            border-right: 0;
          }
        }
        .content__line:nth-child(odd) {
          background-color: #efefef;
        }
        .content__line:nth-child(2n) {
          background-color: #f6f6f6;
        }
      }
      &.big {
        .table__content {
          .content__line {
            .title__line:first-of-type,
            span:first-of-type {
              width: 20%;
            }
            .title__line:nth-of-type(2),
            span:nth-of-type(2) {
              width: 40%;
            }
            .title__line:last-of-type,
            span:last-of-type {
              width: 40%;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1169px) and (min-width: 768px) {
  .component {
    &__table {
      .table__content {
        width: 100%;
        .content__left {
          border-right: 4px solid #fff;
          width: 647px;
        }
      }
      &--3-column {
        .table__content {
          .content__side {
            .title__side {
              padding: 0 11px;
            }
          }
          .content__left {
            width: 500px;
          }
          .content__mid {
            width: 103px;
            .title__mid {
              border-right: 2px solid #fff;
            }
            span {
              border-right: 2px solid #fff;
            }
          }
        }
      }
      &--3-split {
        .table__content {
          .content__left {
            width: 245px;
          }
          .content__mid {
            width: 253px;
          }
          .content__split__side:first-of-type {
            border-right: 4px solid #fff;
          }
        }
      }
      &--regras {
        .table__content {
          .content__left {
            width: 80px;
          }
          .content__mid {
            width: 200px;
          }
          .content__right {
            width: 200px;
          }
        }
      }
      &--full-3 {
        .table__content {
          .content__line {
            .title__line,
            span {
              border-right: 4px solid #fff;
            }
            .title__line:last-of-type,
            span:last-of-type {
              border-right: 0;
            }
          }
        }
      }
      &--4-column {
        .table__content {
          .content__line {
            .title__line,
            span {
              border-right: 4px solid #fff;
            }
            .title__line:last-of-type,
            span:last-of-type {
              border-right: 0;
            }
          }
        }
      }
      &--full-split {
        .table__content {
          flex-direction: column;
          .content__line {
            display: flex;
            width: 100%;
            .title__line {
              align-items: center;
              border-bottom: 1px solid #fff;
              border-right: 1px solid #fff;
              display: flex;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 1.4rem;
              font-weight: 500;
              justify-content: center;
              line-height: 1.4;
              margin: 0;
              min-height: 42px;
              padding: 10px;
              text-align: center;
              background-color: #1bbcef;
              color: #fff;
            }
            .title__line:first-of-type {
              width: 12%;
            }
            .title__line:nth-of-type(2) {
              width: 44%;
            }
            .title__line:last-of-type {
              width: 44%;
            }
            span {
              align-items: center;
              border-bottom: 1px solid #fff;
              display: flex;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 1.2rem;
              font-weight: 500;
              padding-left: 16px;
              width: 100%;
              color: #272832;
              min-height: 42px;
              margin: 0;
              justify-content: center;
              line-height: 1.4;
              text-align: center;
              padding: 10px;
              border-right: 1px solid #fff;
            }
            span:first-of-type {
              width: 12%;
            }
            span:nth-of-type(2) {
              width: 22%;
            }
            span:nth-of-type(3) {
              width: 22%;
            }
            span:nth-of-type(4) {
              width: 22%;
            }
            span:last-of-type {
              width: 22%;
              border-right: 0;
            }
          }
          .content__line:nth-child(odd) {
            background-color: #efefef;
          }
          .content__line:nth-child(2n) {
            background-color: #f6f6f6;
          }
        }
      }
    }
    &__relatorios {
      width: 100%;

      .relatorio__box {
        width: 229px;
        span {
          margin-right: 1px;
        }
        a {
          width: 190px;
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .component {
    &__table {
      .table__content {
        width: 100%;
        .content__side {
          .title__side {
            align-items: center;
            border-bottom: 1px solid #fff;
            display: flex;
            font-family: Arial, Helvetica, sans-serif;
            font-size: 1.2rem;
            padding-left: 12px;
            font-weight: 500;
            width: 100%;
          }
          span {
            font-size: 1.2rem;
            padding-left: 12px;
          }
        }
        .content__left {
          width: 76%;
        }
        .content__right {
          width: 24%;
        }
      }
      &--3-column {
        .table__content {
          .content__side {
            span {
              padding: 0 12px;
            }
          }
          .content__left {
            width: 40%;
          }
          .content__mid {
            width: 32%;
          }
          .content__right {
            width: 28%;
          }
        }
      }
      &--3-split {
        .table__content {
          width: 100%;
          .content__left {
            width: 16%;
            .title__left,
            span {
              font-size: 1.8vw;
            }
          }
          .content__mid {
            width: 42%;
            .title__mid,
            span {
              font-size: 1.8vw;
            }
          }
          .content__right {
            width: 42%;
            .title__right,
            span {
              font-size: 1.8vw;
            }
          }
        }
      }
      &--regras {
        .table__content {
          .content__left {
            width: 20%;
          }
          .content__mid {
            width: 40%;
          }
          .content__right {
            width: 40%;
          }
        }
      }
      &--4-column {
        .table__content {
          .content__line {
            .title__line,
            span {
              font-size: 1.8vw;
            }
          }
        }
      }
      &--full {
        .table__content {
          flex-direction: column;
          .content__line {
            display: flex;
            width: 100%;
            span {
              align-items: center;
              border-bottom: 1px solid #fff;
              display: flex;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 1.2rem;
              font-weight: 500;
              padding-left: 16px;
              width: 100%;
              color: #272832;
              min-height: 42px;
              margin: 0;
              justify-content: center;
              line-height: 1.4;
              text-align: center;
              padding: 10px;
            }
            span:first-of-type {
              width: 30%;
              border-right: 1px solid #fff;
            }
            span:last-of-type {
              width: 70%;
            }
          }
          .content__line:nth-child(odd) {
            background-color: #efefef;
          }
          .content__line:nth-child(2n) {
            background-color: #f6f6f6;
          }
        }
      }
      &--full-3 {
        .table__content {
          flex-direction: column;
          .content__line {
            display: flex;
            width: 100%;
            .title__line {
              align-items: center;
              border-bottom: 1px solid #fff;
              border-right: 1px solid #fff;
              display: flex;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 1.2rem;
              font-weight: 500;
              justify-content: center;
              line-height: 1.4;
              margin: 0;
              min-height: 42px;
              padding: 10px;
              text-align: center;
              background-color: #1bbcef;
              color: #fff;
            }
            .title__line:first-of-type {
              width: 36%;
            }
            .title__line:nth-of-type(2) {
              width: 32%;
            }
            .title__line:last-of-type {
              width: 32%;
            }
            span {
              align-items: center;
              border-bottom: 1px solid #fff;
              display: flex;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 1.2rem;
              font-weight: 500;
              padding-left: 16px;
              width: 100%;
              color: #272832;
              min-height: 42px;
              margin: 0;
              justify-content: center;
              line-height: 1.4;
              text-align: center;
              padding: 10px;
              border-right: 1px solid #fff;
            }
            span:first-of-type {
              width: 36%;
            }
            span:nth-of-type(2) {
              width: 32%;
            }
            span:last-of-type {
              width: 32%;
              border-right: 0;
            }
          }
          .content__line:nth-child(odd) {
            background-color: #efefef;
          }
          .content__line:nth-child(2n) {
            background-color: #f6f6f6;
          }
        }
        &.big {
          .table__content {
            .content__line {
              .title__line,
              span {
                font-family: Arial, Helvetica, sans-serif;
                font-size: 1.2rem;
              }
              .title__line:first-of-type,
              span:first-of-type {
                width: 36%;
              }
              .title__line:nth-of-type(2),
              span:nth-of-type(2) {
                width: 32%;
              }
              .title__line:last-of-type,
              span:last-of-type {
                width: 32%;
              }
            }
          }
        }
      }
      &--full-split {
        .table__content {
          flex-direction: column;
          .content__line {
            display: flex;
            width: 100%;
            .title__line {
              align-items: center;
              border-bottom: 1px solid #fff;
              border-right: 1px solid #fff;
              display: flex;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 1.8vw;
              font-weight: 500;
              justify-content: center;
              line-height: 1.4;
              margin: 0;
              min-height: 42px;
              padding: 10px;
              text-align: center;
              background-color: #1bbcef;
              color: #fff;
            }
            .title__line:first-of-type {
              width: 16%;
            }
            .title__line:nth-of-type(2) {
              width: 42%;
            }
            .title__line:last-of-type {
              width: 42%;
            }
            span {
              align-items: center;
              border-bottom: 1px solid #fff;
              display: flex;
              font-family: Arial, Helvetica, sans-serif;
              font-size: 1.8vw;
              font-weight: 500;
              padding-left: 16px;
              width: 100%;
              color: #272832;
              min-height: 42px;
              margin: 0;
              justify-content: center;
              line-height: 1.4;
              text-align: center;
              padding: 10px;
              border-right: 1px solid #fff;
            }
            span:first-of-type {
              width: 16%;
            }
            span:nth-of-type(2) {
              width: 21%;
            }
            span:nth-of-type(3) {
              width: 21%;
            }
            span:nth-of-type(4) {
              width: 21%;
            }
            span:last-of-type {
              width: 21%;
              border-right: 0;
            }
          }
          .content__line:nth-child(odd) {
            background-color: #efefef;
          }
          .content__line:nth-child(2n) {
            background-color: #f6f6f6;
          }
        }
      }
    }
    &__planos {
      &__wrapper {
        flex-direction: column;
        width: 100%;
      }
      &__block {
        width: 100%;
        margin-bottom: 30px;
      }
      &__list {
        li {
          a {
            width: 100%;
          }
        }
      }
    }
    &__relatorios {
      width: 100%;
      .relatorio__box {
        height: inherit;
        min-height: 357px;
        padding: 0 22px 30px;
        width: 100%;
        a {
          font-size: 1.5rem;
        }
      }
      .relatorio__ano {
        font-size: 2.5rem;
        margin-top: 15px;
      }
      .relatorio__img {
        margin-bottom: 24px;
      }
      .relatorio__box__helper {
        margin: 0 auto;
        max-width: 325px;
        width: 100%;
      }
    }
  }
}
.interna-planos {
  .planinterna__content__full {
    width: 100%;
  }
}
.inss-interna {
  .component__table--full-3
    .table__content
    .content__line
    .title__line:first-of-type {
    width: 12%;
  }
  .component__table--full-3
    .table__content
    .content__line
    .title__line:last-of-type {
    width: 44%;
  }
  .component__table--full-3
    .table__content
    .content__line
    .title__line:nth-of-type(2) {
    width: 44%;
  }
  .component__table--full-3 .table__content .content__line span:first-of-type {
    width: 12%;
  }
  .component__table--full-3 .table__content .content__line span:nth-of-type(2) {
    width: 44%;
  }
  .component__table--full-3 .table__content .content__line span:last-of-type {
    width: 44%;
  }
}
.body__planos {
  .overlay__passo {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 324px;
    border: 1px solid #6b1f81;
    padding: 25px 20px;
    background-color: #fff;
    z-index: 9;
    .close__overlay {
      top: 10px;
      right: 8px;
    }
    h5 {
      color: #9e3b72;
      font-family: "InterstatePlus-Regular";
      font-size: 20px;
      letter-spacing: -0.025em;
      line-height: 1.2;
      width: 215px;
    }
    p {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 14px;
      line-height: 1.7;
      margin-bottom: 15px !important;
      letter-spacing: -0.01em;
      color: #333333;
    }
    ul {
      margin-bottom: 15px;
      li {
        list-style-type: disc;
        list-style-position: inside;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        line-height: 1.7;
        letter-spacing: -0.01em;
        color: #333333;
      }
    }
    &:before {
      content: "";
      position: absolute;
      left: -10px;
      top: 27px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent #6c1f81 transparent transparent;
    }
    &:after {
      content: "";
      position: absolute;
      left: -9px;
      top: 27px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent #fff transparent transparent;
    }
    &--1 {
      left: inherit;
      right: 204px;
      top: -23px;
    }
    &--2 {
      left: inherit;
      right: 204px;
      top: 59px;
    }
    &--3 {
      left: inherit;
      right: 204px;
      top: 142px;
    }
    &--4 {
      left: inherit;
      right: 204px;
      top: 223px;
    }
    &--5 {
      left: inherit;
      right: 204px;
      top: 306px;
    }
    &--6 {
      left: inherit;
      right: 204px;
      top: 389px;
    }
    &--7 {
      left: inherit;
      right: 204px;
      top: 469px;
    }
    &--8 {
      left: inherit;
      right: 204px;
      top: 552px;
    }
    &--9 {
      left: inherit;
      right: 204px;
      top: 632px;
    }
    &--10 {
      left: inherit;
      right: 178px;
      top: 777px;
    }
    &--11 {
      left: inherit;
      right: 348px;
      top: 764px;
    }
    &--12 {
      left: inherit;
      right: 348px;
      top: 823px;
    }
    &--13 {
      left: inherit;
      right: 348px;
      top: 872px;
    }
    &--14 {
      left: inherit;
      right: 348px;
      top: 865px;
    }
    &--15 {
      left: inherit;
      right: 348px;
      top: 995px;
    }
    &--16 {
      left: inherit;
      right: 348px;
      top: 1010px;
    }
    &--17 {
      left: inherit;
      right: 0;
      top: 1070px;
    }
  }
  .passo__map {
    position: relative;
  }
  .lpb__options__content h5 {
    margin-bottom: 10px;
  }
  .wrapper__aposentadoria {
    cursor: pointer;
  }
  #content01 {
    img {
      max-width: 100%;
      height: auto;
    }
    a {
      color: #000;
      font-weight: bold;
    }
  }
  .relatorio__box {
    .lpb__options__content img {
      margin-bottom: 15px;
      display: block;
    }
  }

  .formularios__content {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    width: 100%;
    .formularios__block {
      width: 403px;
    }
    .formulario__title {
      -webkit-align-items: center;
      align-items: center;
      border-radius: 20px;
      color: #fff;
      display: -webkit-flex;
      display: flex;
      font-family: "InterstatePlus-Regular";
      font-size: 2rem;
      font-weight: 500;
      height: 45px;
      letter-spacing: -0.01em;
      padding-left: 28px;
      width: 100%;
      &--purple {
        background-color: #701d82;
      }
      &--blue {
        background-color: #00bef2;
      }
    }
    .formulario__list {
      li {
        -webkit-align-items: center;
        align-items: center;
        border-bottom: 1px solid #d1d1d1;
        color: #272832;
        display: -webkit-flex;
        display: flex;
        font-family: InterstatePlus-Regular;
        font-size: 1.8rem;
        font-weight: 500;
        height: 52px;
        letter-spacing: -0.01em;
        margin: 0;
        padding-left: 62px;
        padding: 0;
        position: relative;
        a {
          -webkit-align-items: center;
          align-items: center;
          border-bottom: 1px solid #d1d1d1;
          color: #272832;
          display: -webkit-flex;
          display: flex;
          font-family: "InterstatePlus-Regular";
          font-size: 1.8rem;
          font-weight: 500;
          height: 52px;
          letter-spacing: -0.01em;
          margin: 0;
          padding-left: 62px;
          text-decoration: none;
          &:before {
            content: url(../img/ico-download-form.png);
            display: inline;
            height: 20px;
            left: 26px;
            position: absolute;
            top: 15px;
            width: 20px;
          }
        }
      }
      &--blue {
        li {
          a {
            &:before {
              content: url(../img/ico-download-form-blue.png);
              display: inline;
              height: 20px;
              left: 0;
              left: 26px;
              position: absolute;
              top: 15px;
              width: 20px;
            }
          }
        }
      }
    }
  }
  .lpb__options li {
    min-height: 50px;
    height: inherit;
    label {
      min-height: 50px;
      height: inherit;
      padding: 5px 18px;
    }
  }
  .overlay__planos {
    width: 546px;
    min-height: 176px;
    height: inherit;
  }
  .planinterna__beneficios {
    h5 {
      margin-bottom: 35px;
    }
    h6 {
      font-family: Arial, Helvetica, sans-serif;
      font-size: 1.6rem;
      font-weight: 700;
      line-height: 2.2rem;
      margin-bottom: 8px;
    }
    .beneficios__block {
      display: -webkit-flex;
      display: flex;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      margin-bottom: 24px;
      width: 850px;
    }
    .beneficio__content {
      width: 650px;
    }
    .beneficio__img {
      padding-left: 28px;
      padding-top: 5px;
    }
  }
}
.planinterna__block--perguntas {
  margin-top: 46px;
}
.plano-principal {
  .planinterna__content__left .planinterna__block .select__opcoes__label {
    cursor: inherit;
  }
}
.investimentos {
  .lpb__content__desc {
    img {
      margin: 0 auto 25px;
      display: block;
    }
  }
}
.sobre-citiprevi {
  .lpa__content__right {
    h3 {
      color: #272832;
      font-family: "InterstatePlus-Bold";
      font-size: 3.2rem;
      letter-spacing: -0.01em;
      margin-bottom: 26px;
    }
  }
  .btn__close__entidade {
    background-image: url(../img/overlay-close.png);
    cursor: pointer;
    height: 29px;
    position: absolute;
    right: 8px;
    top: 10px;
    width: 30px;
  }
  .entidade__wrapper {
    position: relative;
    .entidade__box {
      width: 213px;
      transition: opacity 1s ease-out;
      opacity: 0;
      height: 0;
      padding: 24px 12px;
      background-color: #fff;
      display: none;
      z-index: 99;
      box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
      &:after {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 13px 14px 13px;
        border-color: transparent transparent #6d1f80 transparent;
        content: "";
        position: absolute;
        top: -14px;
        left: 109px;
        z-index: 98;
      }
      &:before {
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 0 13px 14px 13px;
        border-color: transparent transparent #fff transparent;
        content: "";
        position: absolute;
        top: -13px;
        left: 109px;
        z-index: 99;
      }
      .title {
        color: #9e3a73;
        font-size: 2rem;
        font-family: "InterstatePlus-Bold";
        letter-spacing: -0.025em;
      }
      .text {
        ul {
          padding: 10px 5px 10px 5px;
          margin: 0;
          li {
            font-size: 13px;
            padding: 0 0 5px 0;
            margin: 0;
            font-family: Arial, Helvetica, sans-serif;
            line-height: 19px;
          }
        }
      }
    }

    .entidade__conselho--deliberativo {
      min-height: 382px;
      height: inherit;
      border: 1px solid #6c1f80;
      width: 390px;
      position: absolute;
      top: 161px;
    }
    .entidade__conselho--fiscal {
      min-height: 382px;
      height: inherit;
      border: 1px solid #6c1f80;
      width: 390px;
      position: absolute;
      top: 161px;
      &:after {
        left: 96px;
      }
      &:before {
        left: 96px;
      }
    }
    .entidade__conselho--diretoria {
      min-height: 382px;
      height: inherit;
      border: 1px solid #6c1f80;
      width: 390px;
      position: absolute;
      top: 161px;
      &:after {
        left: 82px;
      }
      &:before {
        left: 82px;
      }
    }
    .entidade__conselho--comite {
      min-height: 382px;
      height: inherit;
      border: 1px solid #6c1f80;
      width: 100%;
      position: absolute;
      left: 0;
      top: 238px;
      padding-bottom: 0;
      .text {
        ul {
          li {
            padding-bottom: 0;
          }
        }
      }
      &:after {
        left: 314px;
      }
      &:before {
        left: 314px;
      }
    }
    .entidade__conselho--fiscal.open {
      margin-left: 225px;
      opacity: 1;
      height: auto;
      display: block;
    }
    .entidade__conselho--diretoria.open {
      margin-left: 443px;
      opacity: 1;
      height: auto;
      display: block;
    }
    .entidade__conselho.open {
      opacity: 1;
      height: auto;
      display: block;
    }
  }
}
.o-que-fazemos {
  #content01 {
    sub {
      font-size: 12px;
      font-family: Arial, Helvetica, sans-serif;
      font-weight: 500;
      line-height: 16px;
      color: #393a42;
      margin-bottom: 22px;
      display: block;
      a {
        display: inline-block;
        font-size: 12px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        line-height: 16px;
        color: #393a42;
      }
    }
    .planinterna__block--half {
      sub {
        margin-bottom: 0;
      }
    }
  }
  .lpb__options li label {
    padding-right: 42px;
  }
  .lpb__options__content {
    img {
      display: block;
      margin-bottom: 25px;
    }
  }
  .planinterna__half {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    .planinterna__block--half {
      width: 408px;
      sub {
        font-size: 12px;
        font-family: Arial, Helvetica, sans-serif;
        font-weight: 500;
        line-height: 16px;
        color: #393a42;
        a {
          display: inline-block;
          font-size: 12px;
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 500;
          line-height: 16px;
          color: #393a42;
        }
      }
      h5 {
        color: #393a42;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 2.2rem;
        line-height: 2.6rem;
        font-weight: 700;
        margin-bottom: 20px;
      }
    }
  }

  .links__uteis {
    margin-bottom: 22px;
  }
  .lpb__options__content {
    a {
      color: #666;
      display: inline-block;
      font-family: "InterstatePlus-Regular";
      font-size: 1.5rem;
      margin-bottom: 5px;
      text-decoration: underline;
      display: block;
    }
  }
}
.perguntas-frequentes {
  .planinterna__content__left p {
    margin-bottom: 22px;
  }
  .planinterna__content__left .select__opcoes__label p:last-of-type {
    margin-bottom: 0;
  }
  .planinterna__content__left
    .select__opcoes__label
    .planinterna__block
    p:last-of-type {
    margin-bottom: 22px;
  }
  .planinterna__content__left .planinterna__block .select__opcoes__label {
    cursor: inherit;
  }
}
.planinterna__content__left {
  .flash__aviso {
    color: #00bef2;
    font-weight: bold;
  }
}
.investimentos {
  .lpb__options li label {
    padding-right: 48px;
  }
}
@media (min-width: 768px) and (max-width: 1169px) {
  .body__planos {
    .overlay__passo {
      &--1 {
        right: 98px;
      }
      &--2 {
        right: 98px;
      }
      &--3 {
        right: 98px;
      }
      &--4 {
        right: 98px;
      }
      &--5 {
        right: 98px;
      }
      &--6 {
        right: 98px;
      }
      &--7 {
        right: 98px;
      }
      &--8 {
        right: 98px;
      }
      &--9 {
        right: 98px;
      }
      &--10 {
        right: 72px;
      }
      &--11 {
        right: 246px;
      }
      &--12 {
        right: 246px;
      }
      &--13 {
        right: 246px;
      }
      &--14 {
        right: 246px;
      }
    }
    .planinterna__beneficios {
      .beneficios__block {
        width: 100%;
      }
      .beneficio__content {
        width: 540px;
        p {
          font-family: Arial, Helvetica, sans-serif;
          font-size: 1.6rem;
          font-weight: 400;
          line-height: 1.5;
          margin-bottom: 22px;
        }
      }
    }
    .formularios__content {
      .formularios__block {
        width: 350px;
        .formulario__list li a {
          font-size: 1.6rem;
          padding-left: 53px;
          &:before {
            left: 17px;
          }
        }
      }
    }
    .lpb__options li label {
      min-height: inherit;
      height: 100%;
      padding-top: 10px !important;
      padding-bottom: 10px !important;
    }
  }
  .noticia__interna {
    .planinterna__content h3 {
      min-height: 90px;
      height: inherit;
      padding: 10px 0px;
    }
  }
  #flash object {
    width: 100% !important;
    height: 612px !important;
  }
  .investimentos {
    .lpb__options {
      li label {
        padding-right: 0;
      }
    }
    .lpb__content__desc {
      img {
        max-width: 100%;
        height: auto;
      }
      .lpb__options li label {
        text-align: center !important;
      }
    }
  }

  .o-que-fazemos {
    .videos__box {
      width: 500px;
      height: inherit;
      padding: 0;
    }
    .videos__desc {
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .planinterna__half {
      flex-direction: column;
      margin-bottom: 0;
      .planinterna__block--half {
        width: 100%;
        margin-bottom: 35px;
      }
    }
    .planinterna__content__left h5 {
      font-size: 2rem;
      line-height: 2.6rem;
    }
    .lpb__options li label {
      padding: 0;
      text-align: center;
    }
  }
  .sobre-citiprevi {
    .lpa__content__left ~ .lpa__content__right {
      padding-left: 40px;
    }
    .lpa__content__right {
      h3 {
        font-size: 3rem;
      }
      .entidade__wrapper {
        .entidade__box {
          padding: 12px 10px;
          width: 360px;
          &:before {
          }
          &:after {
          }
          .title {
            font-size: 1.8rem;
          }
        }
        .entidade__conselho--deliberativo {
          top: 118px;
        }
        .entidade__conselho--fiscal {
          top: 118px;
          margin-left: 126px;
        }
        .entidade__conselho--diretoria {
          top: 118px;
          margin-left: 110px;
          &:before {
            left: 270px;
          }
          &:after {
            left: 270px;
          }
        }
        .entidade__conselho--comite {
          top: 182px;
          width: 100%;
          &:before {
            left: 230px;
          }
          &:after {
            left: 230px;
          }
        }
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }
    .lpa__content__left {
      padding-top: 0;
      img {
        max-width: 100%;
      }
    }
  }
}
@media (max-width: 767px) {
  .lpb__content__desc
    #item06Content:checked
    ~ .lpb__options
    li:nth-of-type(6)
    label:after {
    display: none;
    content: none;
  }
  .interna-noticias {
    .planinterna__content {
      h3 {
        min-height: 68px;
        padding: 10px 0;
        height: inherit;
      }
    }
  }
  .situacao-venda {
    .planinterna__content h3 {
      min-height: 68px;
      padding: 10px 0;
      height: inherit;
    }
  }
  .noticia__interna {
    .planinterna__content h3 {
      min-height: 68px;
      height: inherit;
      padding: 10px 0;
    }
  }
  .investimentos {
    .lpb__option__mobile {
      min-height: 43px;
      height: inherit;
      padding: 5px 30px 5px 18px;
    }
  }
  .o-que-fazemos {
    .videos__box {
      width: 90%;
      height: inherit;
      margin: 0 auto;
      padding: 0;
      min-height: inherit;
      a {
        padding: 0 !important;
      }
    }
    .lpb__options__content img {
      max-width: 100%;
      display: block;
      height: auto;
    }
    .planinterna__half {
      flex-direction: column;
      margin-bottom: 0;
      .planinterna__block--half:last-of-type {
        margin-bottom: 0;
      }
      .planinterna__block--half {
        width: 100%;
        margin-bottom: 35px;
        p {
          margin-bottom: 25px;
          min-height: inherit;
          font-size: 1.5rem;
          line-height: 1.2;
        }
        sub {
          padding: 0 8px;
          display: block;
          a {
            padding: 0;
          }
        }
        h5 {
          font-size: 1.7rem;
          line-height: 1.2;
          margin-bottom: 18px;
        }
      }
    }
    .planinterna__content__left h5 {
      font-size: 1.7rem;
      line-height: 1.2;
      margin-bottom: 18px;
    }

    .lpb__option__mobile {
      min-height: 43px;
      height: inherit;
      padding: 5px 18px;
    }
  }
  .investimentos {
    .lpb__content__desc {
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  #flash object {
    width: 100% !important;
    height: 300px !important;
  }
  .sobre-citiprevi {
    .entidade__box {
      margin: 0 !important;
      width: 110% !important;
      left: -5% !important;
      top: 65% !important;
      .title {
        font-size: 1.8rem !important;
      }
    }
    .entidade__conselho--deliberativo {
      &:before {
        left: 16% !important;
      }
      &:after {
        left: 16% !important;
      }
    }
    .entidade__conselho--fiscal {
      &:before {
        left: 45% !important;
      }
      &:after {
        left: 45% !important;
      }
    }
    .entidade__conselho--diretoria {
      &:before {
        left: 72% !important;
      }
      &:after {
        left: 72% !important;
      }
    }
    .entidade__conselho--comite {
      top: 94% !important;
      &:after {
        left: 45% !important;
      }
      &:before {
        left: 45% !important;
      }
    }

    .lpa__content__right {
      h3 {
        font-size: 2.4rem;
      }
    }
  }
  .o-que-fazemos {
    .lpb__options__content {
      a {
        padding: 0 8px;
      }
    }
  }
  .perguntas-frequentes {
    .landing-planos {
      .page__header__title {
        padding-top: 50px;
      }
    }
  }
  .body__planos {
    .passo__map {
      img {
        max-width: 300px;
        height: auto;
      }
    }
    .overlay__passo {
      width: 100%;
      &:after {
        background-color: #fff;
        border: 1px solid #fff;
        height: 20px;
        left: 100px;
        top: -10px;
        width: 20px;
        transform: rotate(45deg);
      }
      &:before {
        border-style: solid;
        border-width: 10px 10px 10px 0;
        content: "";
        height: 0;
        position: absolute;
        top: 27px;
        width: 0;
        background-color: #fff;
        border: none;
        height: 20px;
        left: 100px;
        border: 1px solid #6c1f81;
        top: -12px;
        width: 20px;
        transform: rotate(45deg);
      }
      &--1 {
        top: 75px;
        right: 0;
      }
      &--2 {
        top: 140px;
        right: 0;
      }
      &--3 {
        top: 212px;
        right: 0;
      }
      &--4 {
        top: 280px;
        right: 0;
      }
      &--5 {
        top: 348px;
        right: 0;
      }
      &--6 {
        top: 418px;
        right: 0;
      }
      &--7 {
        top: 488px;
        right: 0;
      }
      &--8 {
        top: 560px;
        right: 0;
      }
      &--9 {
        top: 622px;
        right: 0;
      }
      &--10 {
        top: 774px;
        right: 0;
        &:after {
          left: 210px;
        }
        &:before {
          left: 210px;
        }
      }
      &--11 {
        top: 732px;
        right: 0;
        &:after {
          left: 66px;
        }
        &:before {
          left: 66px;
        }
      }
      &--12 {
        top: 775px;
        right: 0;
        &:after {
          left: 66px;
        }
        &:before {
          left: 66px;
        }
      }
      &--13 {
        top: 820px;
        right: 0;
        &:after {
          left: 66px;
        }
        &:before {
          left: 66px;
        }
      }
      &--14 {
        top: 865px;
        right: 0;
        &:after {
          left: 66px;
        }
        &:before {
          left: 66px;
        }
      }
      &--15 {
        top: 944px;
        right: 0;
        &:after {
          left: 66px;
        }
        &:before {
          left: 66px;
        }
      }
      &--16 {
        top: 1010px;
        right: 0;
        &:after {
          left: 66px;
        }
        &:before {
          left: 66px;
        }
      }
    }
    .wrapper__aposentadoria {
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .overlay__iframe {
      img {
        max-width: 100%;
        height: auto;
      }
    }
    .formularios__content {
      flex-direction: column;
      width: 100%;
      .formularios__block {
        width: 100%;
        margin-bottom: 30px;
        .formulario__list {
          li {
            font-size: 1.6rem;
            height: 60px;
            line-height: 2.4rem;
            padding-right: 16px;
            a {
              font-size: 1.6rem;
              height: 60px;
              line-height: 2.4rem;
            }
          }
        }
      }
    }
    .lpb__option__mobile {
      min-height: 43px;
      height: inherit;
      padding: 5px 32px 5px 18px;
    }
    .beneficios__block {
      -webkit-flex-direction: column;
      flex-direction: column;
      width: 100% !important;
      display: flex;
      margin-bottom: 24px;
    }
    .beneficios__block:first-of-type {
      .beneficio__img {
        img {
          width: 100px;
        }
      }
    }
    .beneficio__img {
      margin-bottom: 15px;
      padding-left: 0 !important;
      padding-top: 5px;
    }
    .planinterna__beneficios {
      .beneficio__content {
        width: 100%;
        p {
          font-family: Arial, Helvetica, sans-serif !important;
          font-size: 1.6rem !important;
          font-weight: 400 !important;
          line-height: 1.5 !important;
          padding-left: 0 !important;
          margin-bottom: 22px !important;
        }
      }
    }
  }
}
@media (max-width: 767px) and (orientation: landscape) {
}
